import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import {
    Grid,
    Button,
    Dialog,
    withStyles,
    CircularProgress
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Fade } from 'react-reveal';

import { withContext } from '../../services/context';
import styles from './styles';
import Table from '../../components/Table';
import RowActions from '../../components/Table/RowActions';
import BlogPostForm from '../../components/BlogPostForm';
import { API_URL, fetchBlog, updatePublishPost, removePost } from '../../services/axios';
import ConfirmationModal from '../../components/ConfirmationModal';

const BlogPost = ({ classes, context: { posts, dispatch } }) => {
    const [formOpen, setFormOpen] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationProps, setConfirmationProps] = useState(null);
    const [selectedPost, setSelectedPost] = useState(null);

    const handleFormOpen = () => setFormOpen(true);

    const handleFormClose = () => {
        setSelectedPost(null);
        setFormOpen(false);
    };

    const handleConfirmationOpen = () => setConfirmationOpen(true);

    const handleConfirmationClose = () => setConfirmationOpen(false);

    const editPost = post => {
        setSelectedPost(post);
        handleFormOpen();
    }

    const publishPost = post => {
        setConfirmationProps({
            title: "Publicar publicação",
            text: `Deseja publicar a publicação ${post.title_pt}?`,
            action: async () => {
                const response = await updatePublishPost(post._id, !post.published);
                dispatch({
                    type: "UPDATE_POST",
                    payload: response.data.content
                })
            }
        })
        handleConfirmationOpen();
    }

    const deletePost = post => {
        setConfirmationProps({
            title: "Apagar publicação",
            text: `Deseja apagar a publicação ${post.title_pt}?`,
            action: async () => {
                const response = await removePost(post._id);
                dispatch({
                    type: "DELETE_POST",
                    payload: response.data.content
                })
            }
        });
        handleConfirmationOpen();
    }

    const actions = {
        edit: editPost,
        publish: publishPost,
        delete: deletePost
    }

    const columns = [
        { title: 'Ações', render: row => (
            <RowActions target={row} actions={actions} />
        )},
        { title: 'ID', field: '_id', hidden: true },
        { title: 'Thumbnail', field: 'thumbnail.filename', render: row =>  
            <img
                className={classes.thumbnail}
                src={`${API_URL}/warehouse/${row.thumbnail?.filename}`} 
                alt="thumbnail"
            />
        },
        { title: 'Título', field: 'title_pt' },
        { title: 'Categoria', field: 'category?.title_pt', render: row => row.category?.title_pt },
        { title: 'Vizualizações', field: 'views' },
        { title: 'Publicado', field: 'published', render: row => 
            row.published ? <CheckIcon /> : <CloseIcon /> 
        }
    ]

    useEffect(() => {
        const getPosts = async () => {
            const response = await fetchBlog();
            dispatch({
                type: "SET_POSTS",
                payload: response.data.content
            })
        };
        getPosts();
    }, [dispatch]);

    if (!posts) {
        return (
            <Grid container justify="center" alignItems="center">
                <CircularProgress size={60}/>
            </Grid>
        )
    }

    return (
        <Fade>
            <ConfirmationModal
                open={confirmationOpen}
                handleClose={handleConfirmationClose}
                {...confirmationProps}
            />
            <Dialog
                open={formOpen}
                onClose={handleFormClose}
                fullScreen
            >
                <BlogPostForm 
                    label={selectedPost ? `Editar ${selectedPost.title_pt}` : "Adicionar publicação" }
                    values={selectedPost}
                    onClose={handleFormClose}
                />
            </Dialog>
            <div className={classes.root}>
                <Grid container direction="column" spacing={4}>
                    <Grid item>
                        <Button 
                            variant="contained"
                            color="primary"
                            onClick={handleFormOpen}
                        >
                            Adicionar publicação
                        </Button>
                    </Grid>
                    <Grid item>
                        <Table title="Publicações" columns={columns} data={posts} />
                    </Grid>
                </Grid>
            </div>
        </Fade>
    )
}

export default compose(
    withContext,
    withStyles(styles)
)(BlogPost);