import React from 'react';
import { compose } from 'recompose';
import {
    Container,
    Grid,
    Paper,
    Typography,
    TextField,
    IconButton,
    withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Fade } from 'react-reveal';

import styles from './styles';

// @TODO: paymentDetails, orderDetails, clientDetails
const PaymentDetails = ({ classes, payment, onClose }) => {
    return (
        <Fade duration={400}>
            <Container className={classes.root}>
                <IconButton className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h5">
                    Pagamento { payment.id }
                </Typography>
                <Grid container spacing={4}>
                    <Grid item>
                        <Paper className={classes.paper}>
                            <Grid container direction="column" spacing={4}>
                                <Typography variant="body1">
                                    Detalhes de pagamento
                                </Typography>
                                <Grid item>
                                    <TextField 
                                        label="Montante"
                                        value={parseFloat(payment.amount/100).toFixed(2)}
                                        InputProps={{
                                            style: { color: "black" }
                                        }}
                                        disabled
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        Morada de faturação
                                    </Typography>
                                    <Grid container spacing={4}>
                                        <Grid item>
                                            <TextField 
                                                label="Rua"
                                                value={payment.billing_details?.address.line1}
                                                InputProps={{
                                                    style: { color: "black" }
                                                }}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField 
                                                label="Porta e Andar"
                                                value={payment.billing_details?.address.line2}
                                                InputProps={{
                                                    style: { color: "black" }
                                                }}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField 
                                                label="Código Postal"
                                                value={payment.billing_details?.address.postalCode}
                                                InputProps={{
                                                    style: { color: "black" }
                                                }}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField 
                                                label="Cidade"
                                                value={payment.billing_details?.address.city}
                                                InputProps={{
                                                    style: { color: "black" }
                                                }}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Fade>
    )
};

export default compose(
    withStyles(styles)
)(PaymentDetails);