export default () => ({
    form: {
        padding: 30,
        width: "70%",
        margin: "auto"
    },
    button: {
        position: "absolute",
        right: 0,
        top: 0
    }
})