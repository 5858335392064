import React from 'react';
import MaterialTable from 'material-table';

const Table = ({ title, columns, data }) => {
    return (
        <MaterialTable
            title={title}
            columns={columns}
            data={data}
            localization={{
                header: {
                    actions: "Ações"
                },
                body: {
                    emptyDataSourceMessage: "Registos não encontrados",
                    editRow: {
                        deleteText: "De certeza que quer apagar este registo?"
                    }
                },
                toolbar: {
                    searchTooltip: "Procurar",
                    searchPlaceholder: "Procurar"
                },
                pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: "registos",
                    labelRowsPerPage: "Registos por página"
                }
            }}
        />
    )
}

export default Table