import React, { useEffect } from 'react';
import { compose } from 'recompose';
import {
    Grid,
    CircularProgress,
    Paper,
    Typography,
} from '@material-ui/core';

import { withContext } from '../../services/context';
import { fetchSettings, fetchSocial } from '../../services/axios';
import SiteSettingsForm from '../../components/SiteSettingsForm';
import SocialForm from '../../components/SocialForm';

const SiteSettings = ({
    context: {
        social,
        siteSettings,
        dispatch
    }
}) => {
    useEffect(() => {
        const getSiteSettings = async () => {
            const response = await fetchSettings();
            dispatch({
                type: 'SET_SITE_SETTINGS',
                payload: response.data.content
            })
        };
        const getSocial = async () => {
            const response = await fetchSocial();
            dispatch({
                type: 'SET_SOCIAL',
                payload: response.data.content
            })
        }
        getSocial();
        getSiteSettings();
    }, [dispatch]);

    if (!siteSettings) {
        return (
            <Grid container justify="center">
                <CircularProgress size={60} />
            </Grid>
        )
    }
    
    return (
        <div>
            <SiteSettingsForm values={siteSettings}/>
            <div style={{ marginLeft: "250px", marginTop: "20px" }}>
                <Paper style={{ maxWidth: "50vw", margin: "auto"}}>
                    <Typography variant="h5">Social</Typography>
                    { social?.map(s => (
                        <SocialForm key={s._id} values={s} />
                    ))}
                    <SocialForm />
                </Paper>
            </div>
        </div>
    );
}

export default compose(
    withContext
)(SiteSettings);