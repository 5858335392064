export default () => ({
    root: {
        marginLeft: "250px",
    },
    paper: {
        maxWidth: "50vw",
        margin: "auto",
        padding: "20px"
    },
    formTitle: {
        marginBottom: "20px"
    },
    inputContainer: {
        flex: "1 0 25%"
    }
})