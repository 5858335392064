import React from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import { Dialog, DialogActions, DialogContent, Button, IconButton, withStyles, Typography, Grid, TextField, FormHelperText } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles';
import DatePicker from '../DatePicker';
import { Fade } from 'react-reveal';
import SubmitButton from '../SubmitButton';
import { postPromoCodes } from '../../services/axios';
import { withContext } from '../../services/context';

const PromoCodeForm = ({ 
    classes, 
    label,
    onClose, 
    values, 
    handleBlur,
    handleChange,
    handleSubmit,
    status, 
    errors,
    touched,
    setStatus,
    setFieldValue,
    isSubmitting
}) => {
    return (
        <form onSubmit={handleSubmit} className={classes.form} encType="multipart/form-data">
            <div className={classes.root}>
                <Dialog open={!!status.formResponseCode}>
                    <DialogContent>
                        <Typography variant="h5">
                            { status.formResponseCode === 201 && "Código promocional guardado com sucesso" }
                            { status.formResponseCode === 200 && "Código promocional alterado com sucesso" }
                            { status.formResponseCode > 201 && "Erro no servidor. Tente mais tarde" }
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={() => setStatus({ formResponseCode: null })}>Ok</Button>
                    </DialogActions>
                </Dialog>
                <IconButton className={classes.button} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h5" className={classes.title}>
                    { label }
                </Typography>
                <Grid container spacing={4} justify="center">
                    <Grid item>
                        <TextField
                            name="code"
                            label="Código"
                            value={values.code}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.code && touched.code}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            name="value"
                            label="Valor (%)"
                            value={values.value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.value && touched.value}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <DatePicker
                            label="startAt"
                            value={values.startAt}
                            onChange={
                                date => setFieldValue("startAt", date)
                            }
                            onBlur={handleBlur}
                        />
                        { !!errors.startAt && touched.startAt && (
                            <Fade duration="200">
                                <FormHelperText error>
                                    { errors.startAt }
                                </FormHelperText>
                            </Fade>
                        )}
                    </Grid>
                    <Grid item>
                        <DatePicker
                            label="endAt"
                            value={values.endAt}
                            onChange={
                                date => setFieldValue("endAt", date)
                            }
                            onBlur={handleBlur}
                        />
                        { !!errors.endAt && touched.endAt && (
                            <Fade duration="200">
                                <FormHelperText error>
                                    { errors.endAt }
                                </FormHelperText>
                            </Fade>
                        )}
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: 20 }} justify="center">
                    <Grid item>
                        <Grid container justify="center" alignItems="center" direction="column">
                            <Grid item>
                                <SubmitButton variant="contained" color="primary" isSubmitting={isSubmitting}>
                                    Submeter
                                </SubmitButton>
                            </Grid>
                            <Grid item>
                                {Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0 && (
                                    <FormHelperText error>
                                        Existem erros no formulário
                                    </FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </form>
    );
};

export default compose(
    withContext,
    withStyles(styles),
    withFormik({
        mapPropsToStatus: () => ({ formResponseCode: null }),
        mapPropsToValues: () => {
            return {
                code: "",
                value: "",
                startAt: Date.now(),
                endAt: Date.now(),
            };
        },
        handleSubmit: async (values, { props: {context: { dispatch } }, setStatus }) => {
            const response = await postPromoCodes({
                ...values,
                startAt: new Date(values.startAt),
                endAt: new Date(values.endAt)
            });
            dispatch({
                type: 'ADD_PROMO_CODE',
                payload: response.data.content
            });
            setStatus({
                formResponseCode: response.data.status
            })
        }
    })
)(PromoCodeForm);