import { createTheme } from '@material-ui/core';
import { COLOR_PRIMARY, COLOR_SECONDARY } from './colors';
import { ptPT } from '@material-ui/core/locale';

export default createTheme({
    palette: {
        primary: { 
            main: COLOR_PRIMARY
        },
        secondary: {
            main: COLOR_SECONDARY
        }
    },
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: '6px',
                background: '#FFFFFF',
                padding: "20px"
            },
            elevation1: {
                boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)'
            },
            elevation2: {
                boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)'
            }
        },
        MuiDialog: {
            paperFullScreen: {
                backgroundColor: "#eee"
            }
        },
        MuiGrid: {
            "spacing-xs-4": {
                width: "100%"
            }
        }
    }
}, ptPT);