import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import {
    Grid,
    Button,
    TextField,
    withStyles,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    CircularProgress
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from '@material-ui/lab';
import styles from './styles';

import { withContext } from '../../services/context';
import { fetchProducts } from '../../services/axios';

const OrderItemsInput = ({
    values,
    onChange,
    context: {
        products,
        dispatch
    }
}) => {
    const [quantity, setQuantity] = useState(1);
    const [product, setProduct] = useState(null);

    const handleOrderItemAdd = () => {
        const newValues = [...values, { quantity, product }];
        onChange(newValues);
    }

    const handleOrderItemRemove = index => {
        const newValues = values.filter((v, i) => i !== index);
        onChange(newValues);
    }

    useEffect(() => {
        const getProducts = async () => {
            const response = await fetchProducts();
            dispatch({
                type: "SET_PRODUCTS",
                payload: response.data.content
            })
        };
        getProducts();
    }, [dispatch]);

    if (!products) {
        return (
            <Grid container justify="center" alignItems="center">
                <CircularProgress size={24} />
            </Grid>
        )
    }

    return (
        <Grid container direction="column" spacing={4}>
            <Grid item>
                <Grid container spacing={4} justify="center" alignItems="center">
                    <Grid item>
                        <TextField 
                            name="quantity"
                            label="Quantidade"
                            value={quantity}
                            onChange={e => setQuantity(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            id="products-select"
                            value={product}
                            options={products}
                            getOptionLabel={(option) => option.title_pt}
                            onChange={e => setProduct(products[e.target.value])}
                            style={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Produtos" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOrderItemAdd}
                        >
                            Adicionar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Quantidade</TableCell>
                            <TableCell>Produto</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { values.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={3}>Sem registos</TableCell>
                            </TableRow>
                        )}
                        { values.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{ item.quantity }</TableCell>
                                <TableCell>{ item.product?.title_pt }</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleOrderItemRemove(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )) }
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
}

export default compose(
    withContext,
    withStyles(styles)
)(OrderItemsInput);