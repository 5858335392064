export default () => ({
    root: {
        
    },
    paper: {
        padding: 40
    },
    tableButton: {
        margin: 5
    }
})