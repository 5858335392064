export default () => ({
    root: {

    },
    closeButton: {
        top: 0,
        right: 0,
        position: "fixed"
    },
    paper: {
        padding: "50px"
    }
})