import axios from 'axios';
import { getToken } from '../storage';
import history from '../history';

export const API_URL = process.env.REACT_APP_API_URL;

//ENDPOINTS
const authEndpoint = axios.create({
    baseURL: `${API_URL}/auth`
});

const ordersEndpoint = axios.create({
    baseURL: `${API_URL}/orders`
});

const productsEndpoint = axios.create({
    baseURL: `${API_URL}/products`
});

const variantsEndpoint = axios.create({
    baseURL: `${API_URL}/variants`
});

const blogEndpoint = axios.create({
    baseURL: `${API_URL}/blog`
});

const blogCategoriesEndpoint = axios.create({
    baseURL: `${API_URL}/blog-categories`
});

const helpsEndpoint = axios.create({
    baseURL: `${API_URL}/help`
});

const filesEndpoint = axios.create({
    baseURL: `${API_URL}/files`
});

const paymentsEndpoit = axios.create({
    baseURL: `${API_URL}/payments`
});

const settingsEndpoint = axios.create({
    baseURL: `${API_URL}/settings`
});

const newsletterEndpoint = axios.create({
    baseURL: `${API_URL}/newsletter-emails`
});

const metricsEndpoint = axios.create({
    baseURL: `${API_URL}/metrics`
});

const pagesEndpoint = axios.create({
    baseURL: `${API_URL}/pages`
});

const seoEndpoint = axios.create({
    baseURL: `${API_URL}/seo`
});

const bannersEndpoint = axios.create({
    baseURL: `${API_URL}/banners`
});

const contactInfoEndpoint = axios.create({
    baseURL: `${API_URL}/contact-info`
});

const socialEndpoint = axios.create({
    baseURL: `${API_URL}/social`
})

const promoCodesEndpoint = axios.create({
    baseURL: `${API_URL}/promo-codes`
})

// HEADERS
const withHeaders = async () => ({
    headers: {
        authorization: `Bearer ${await getToken()}`
    }
})

export const makeRequest = async (endpoint, url, method, data = null) => {
    let params = [];
    switch(method) {
        case "get": 
            params = [url, await withHeaders()];
            break;
        case "post":
            params = [url, data, await withHeaders()];
            break;
        case "put":
            params = [url, data, await withHeaders()];
            break;
        case "delete":
            params = [url, await withHeaders()];
            break;
        default:
            return null;
    }
    const response = await endpoint[method](...params);
    if (response.status === 401) {
        history.push("/");
    }
    return response;
}

export const loginUser = async (email, password) => 
    await authEndpoint.post("/login", { email, password });

// PRODUCTS
export const fetchProducts = async (queryString = '?perPage=0') => await makeRequest(productsEndpoint, `${queryString}`, 'get');

export const fetchMostViewedProduct = async () => await makeRequest(productsEndpoint, `?sort=views&order=desc`, 'get');

export const fetchMostBoughtProducts = async () => await makeRequest(productsEndpoint, `?sort=bought&order=desc`, 'get');

export const postProduct = async product => await makeRequest(productsEndpoint, '', 'post', product);

export const putProduct = async product => 
    await makeRequest(productsEndpoint, `/${product._id}`, 'put', product)

export const removeProduct = async id => 
    await makeRequest(productsEndpoint, `/${id}`, 'delete');

export const updatePublishProduct = async (id, published) => 
    await makeRequest(productsEndpoint, `/${id}`, 'put', { published });

// PRODUCT VARIANTS
export const fetchProductVariants = async () => await makeRequest(variantsEndpoint, '', 'get');

export const postProductVariant = async variant =>
    await makeRequest(variantsEndpoint, '', 'post', variant);

export const putProductVariant = async variant => 
    await makeRequest(variantsEndpoint, `/${variant._id}`, 'put', variant);

export const updatePublishVariant = async (id, published) => 
    await makeRequest(variantsEndpoint, `/${id}`, 'put', { published });

export const deleteVariant = async id => 
    await makeRequest(variantsEndpoint, `/${id}`, 'delete');

// BLOG POSTS
export const fetchBlog = async (queryString = '?perPage=0') => await makeRequest(blogEndpoint, `${queryString}`, 'get');

export const postBlogPost = async post => 
    await makeRequest(blogEndpoint, '', 'post', post);

export const putBlogPost = async post => 
    await makeRequest(blogEndpoint, `/${post._id}`, 'put', post);

export const updatePublishPost = async (id, published) =>
    await makeRequest(blogEndpoint, `/${id}`, 'put', { published });

export const removePost = async id => 
    await makeRequest(blogEndpoint, `/${id}`, 'delete');

// BLOG CATEGORIES
export const fetchBlogCategories = async () => 
    await makeRequest(blogCategoriesEndpoint, '', 'get');

export const postBlogCategory = async category =>
    await makeRequest(blogCategoriesEndpoint, '', 'post', category);

export const putBlogCategory = async category => 
    await makeRequest(blogCategoriesEndpoint, `/${category._id}`, 'put', category);

export const updatePublishCategory = async (id, published) => 
    await makeRequest(blogCategoriesEndpoint, `/${id}`, 'put', { published });

export const deleteBlogCategory = async id =>
    await makeRequest(blogCategoriesEndpoint, `/${id}`, 'delete');

// HELP
export const fetchHelps = async () => await makeRequest(helpsEndpoint, '', 'get');

export const postHelp = async help => await makeRequest(helpsEndpoint, '', 'post', help);

export const putHelp = async help => 
    await makeRequest(helpsEndpoint, `/${help._id}`, 'put', help);

export const updatePublishHelp = async (id, published) => 
    await makeRequest(helpsEndpoint, `/${id}`, 'put', { published });

export const deleteHelp = async id => 
    await makeRequest(helpsEndpoint, `/${id}`, 'delete');

// FILES
export const getFile = async id => await makeRequest(filesEndpoint, `/${id}`, 'get');

export const postFile = async file => await filesEndpoint.post('', file, { 
    headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${await getToken()}`
    }
});

// ORDERS
export const fetchOrders = async () => await makeRequest(ordersEndpoint, '', 'get');

export const postOrder = async order => await makeRequest(ordersEndpoint, '', 'post', order);

export const putOrder = async order => await makeRequest(ordersEndpoint, `/${order._id}`, 'put', order);

export const removeOrder = async id => await makeRequest(ordersEndpoint, `/${id}`, 'delete');

export const changeOrderStatus = async (id, status) => 
    await makeRequest(ordersEndpoint, `/${id}/status/`, 'put', { status });

// PAYMENTS
export const fetchPayments = async () => await makeRequest(paymentsEndpoit, '', 'get');

// SETTINGS
export const fetchSettings = async () => await makeRequest(settingsEndpoint, '', 'get');

export const updateSettings = async (id, settings) => 
    await makeRequest(settingsEndpoint, `/${id}`, 'put', settings);

export const fetchHeader = async () => await makeRequest(settingsEndpoint, '/header', 'get');

export const fetchFooter = async () => await makeRequest(settingsEndpoint, '/footer', 'get');

export const updateHeader = async header => await makeRequest(settingsEndpoint, '/header', 'put', header);

export const updateFooter = async footer => await makeRequest(settingsEndpoint, '/footer', 'put', footer);

// NEWSLETTER
export const fetchNewsletter = async () => await makeRequest(newsletterEndpoint, '', 'get');

// METRICS
export const fetchAllMetrics = async () => await makeRequest(metricsEndpoint, '', 'get');

// PAGES
export const fetchPages = async () => await makeRequest(pagesEndpoint, '', 'get');

export const updatePage = async page => await makeRequest(pagesEndpoint, `/${page._id}`, 'put', page);

export const updatePublishPage = async (id, page) => await makeRequest(pagesEndpoint, `/${id}`, 'put', page);

export const removePage = async id => await makeRequest(pagesEndpoint, `/${id}`, 'delete');

// SEO
export const fetchSEO = async () => await makeRequest(seoEndpoint, '', 'get');

export const postSEO = async seo => await makeRequest(seoEndpoint, '', 'post', seo);

// BANNERS
export const fetchBanners = async () => await makeRequest(bannersEndpoint, '', 'get');

export const fetchBanner = async id => await makeRequest(bannersEndpoint, `/${id}`, 'get');

export const updatePublishBanner = async (id, published) => await makeRequest(bannersEndpoint, `/${id}`, 'put', { published });

export const postBanner = async banner => await makeRequest(bannersEndpoint, '', 'post', banner);

export const putBanner = async banner => await makeRequest(bannersEndpoint, `/${banner._id}`, 'put', banner);

export const removeBanner = async id => await makeRequest(bannersEndpoint, `/${id}`, 'delete');

// CONTACT INFO
export const fetchContactInfo = async () => await makeRequest(contactInfoEndpoint, '', 'get');

export const putContactInfo = async contactInfo => await makeRequest(contactInfoEndpoint, `/${contactInfo._id}`, 'put', contactInfo); 

// SOCIAL
export const fetchSocial = async () => await makeRequest(socialEndpoint, '', 'get');

export const putSocial = async social => await makeRequest(socialEndpoint, `/${social._id}`, 'put', social);

export const postSocial = async social => await makeRequest(socialEndpoint, '', 'post', social);

export const removeSocial = async id => await makeRequest(socialEndpoint, `/${id}`, 'delete')

// PROMO CODES
export const fetchPromoCodes = async () => await makeRequest(promoCodesEndpoint, '', 'get');

export const postPromoCodes = async promoCode => await makeRequest(promoCodesEndpoint, '', 'post', promoCode);

export const removePromoCodes = async id => await makeRequest(promoCodesEndpoint, `${id}`, 'delete');

export const updatePublishPromoCode = async (id, published) => 
    await makeRequest(promoCodesEndpoint, `/${id}`, 'put', { published });