import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { Fade } from 'react-reveal';
import { 
    Grid,
    Icon,
    CircularProgress,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    withStyles,
    Typography
} from '@material-ui/core';
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ChartistGraph from "react-chartist";
import moment from 'moment';

import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardBody from "../../components/Card/CardIcon.js";
import styles from './styles';
import './charts.css';
import { chartOptions, chartAnimation } from './charts';
import { withContext } from '../../services/context';
import { 
    fetchAllMetrics, 
    fetchOrders, 
    fetchMostBoughtProducts, 
    fetchMostViewedProduct, 
    API_URL
} from '../../services/axios';

const Dashboard = ({ classes, context: { products, orders, metrics, dispatch } }) => {
    const [transactionsPerMonth, setTransactionsPerMonth] = useState(null);
    const [orderAverageAmount, setOrderAverageAmount] = useState(null);
    const [mostBoughtProducts, setMostBoughtProducts] = useState(null);
    const [mostViewedProduct, setMostViewedProduct] = useState(null);

    useEffect(() => {
        const getAllMetrics = async () => {
            const response = await fetchAllMetrics();
            dispatch({
                type: "SET_METRICS",
                payload: response.data.content
            });
        }
        const getOrders = async () => {
            const response = await fetchOrders();
            dispatch({
                type: "SET_ORDERS",
                payload: response.data.content
            });
        }
        const getMostBoughtProducts = async () => {
            const response = await fetchMostBoughtProducts();
            setMostBoughtProducts(response.data.content.results);
        }
        const getMostViewedProduct = async () => {
            const response = await fetchMostViewedProduct();
            setMostViewedProduct(response.data.content.results);
        }
        getAllMetrics();
        getOrders();
        getMostBoughtProducts();
        getMostViewedProduct();
    }, [dispatch]);

    const orderTransationsPerMonth = transactionsData => {
        let labels = [];
        let series = [];
        let max = 0;
        transactionsData.forEach(transaction => {
            const month = moment(transaction.createdAt).format('MMMM');
            if (!labels.includes(month)) {
                labels.push(month);
                series.push(parseInt(transaction.value));
                if (transaction.value > max) max = parseInt(transaction.value);
            }
            
        });
        const data = {
            labels,
            series: [series],
            max,
            increase: ((series[series.length-2] / series[series.length-1]) * 100).toFixed(1) 
        };
        return data;
    }

    const getOrderAverageAmount = orders => {
        let sum;
        let count;
        orders.forEach(order => {
            sum += parseFloat(order.total);
            count++;
        });
        return sum / count;
    }

    useEffect(() => {
        if (metrics && orders) {
            setTransactionsPerMonth(
                orderTransationsPerMonth(metrics.filter(metric => metric.type === "numberOfTransactionsPerMonth"))
            );
            setOrderAverageAmount(
                getOrderAverageAmount(orders)
            );
        }
    }, [metrics, orders])

    if (!metrics || !orders || !transactionsPerMonth || !mostViewedProduct || !mostBoughtProducts) {
        return (
            <Grid container justify="center" alignItems="center">
                <CircularProgress size={60}/>
            </Grid>
        )
    }

    return (
        <>
            <Grid container spacing={4} className={classes.root}>
                <Grid item className={classes.cardContainer}>
                    <Fade duration={50}>
                        <Card  className={classes.card}>
                            <CardHeader color="warning" stats icon>
                                <CardIcon 
                                    color="primary"
                                    style={{ background: 'linear-gradient(60deg, #ffa726, #fb8c00)'}}
                                >
                                    <Icon>local_grocery_store</Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>Compras completas</p>
                                <h3 className={classes.cardTitle}>
                                    { metrics.filter(metric => metric.type === "completeBuys")[0].value }
                                </h3>
                            </CardHeader>
                        </Card >
                    </Fade>
                </Grid>
                <Grid item className={classes.cardContainer}>
                    <Fade duration={100}>
                        <Card  className={classes.card}>
                            <CardHeader color="warning" stats icon>
                                <CardIcon 
                                    color="primary"
                                    style={{ background: 'linear-gradient(60deg, #66bb6a, #43a047)'}}
                                >
                                    <Icon>mouse</Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>Total de visitas</p>
                                <h3 className={classes.cardTitle}>
                                    { metrics.filter(metric => metric.type === "totalPageViews")[0].value }
                                </h3>
                            </CardHeader>
                        </Card >
                    </Fade>
                </Grid>
                <Grid item className={classes.cardContainer}>
                    <Fade duration={150}>
                        <Card  className={classes.card}>
                            <CardHeader color="warning" stats icon>
                                <CardIcon 
                                    color="primary"
                                    style={{ background: 'linear-gradient(60deg, #ef5350, #e53935)'}}
                                >
                                    <Icon>attach_money</Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>Total de receitas</p>
                                <h3 className={classes.cardTitle}>
                                    { metrics.filter(metric => metric.type === "totalRevenue")[0].value }
                                </h3>
                            </CardHeader>
                        </Card >
                    </Fade>
                </Grid>
            </Grid>
            <Grid container spacing={4} className={classes.root}>
                <Grid item className={classes.cardContainer}>
                    <Card className={classes.card} chart>
                        <CardHeader 
                            color="success"
                            style={{ background: 'linear-gradient(60deg, #26c6da, #00acc1)'}}
                        >
                            <ChartistGraph
                                className="ct-chart"
                                data={transactionsPerMonth}
                                type="Line"
                                options={{
                                    ...chartOptions,
                                    high: transactionsPerMonth.max
                                }}
                                listener={chartAnimation}
                            />
                        </CardHeader>
                        <CardBody className={classes.cardBody}>
                            <h4 className={classes.cardTitle}>Número de transações por mês</h4>
                            <p className={classes.cardCategory}>
                                <span className={classes.successText}>
                                    <ArrowUpward className={classes.upArrowCardCategory} /> {transactionsPerMonth.increase}%
                                </span>{" "}
                                de aumento em transações em relação ao mês passado
                            </p>
                        </CardBody>
                    </Card >
                </Grid>
            </Grid>
            <Grid container spacing={4} className={classes.root}>
                <Grid item className={classes.cardContainer}>
                    <Fade duration={150}>
                        <Card  className={classes.card}>
                            <CardHeader color="warning" stats icon>
                                <CardIcon color="primary">
                                    <Icon>attach_money</Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>Média de valor de encomendas</p>
                                <h3 className={classes.cardTitle}>
                                    { orderAverageAmount || 0 } €
                                </h3>
                            </CardHeader>
                        </Card >
                    </Fade>
                </Grid>
                <Grid item className={classes.cardContainer}>
                    <Fade duration={150}>
                        <Card  className={classes.card}>
                            <CardHeader color="warning" stats icon>
                                <CardIcon color="primary">
                                    <img 
                                        className={classes.productThumbnail}
                                        src={`${API_URL}/warehouse/${mostViewedProduct.thumbnail?.fileName}`} 
                                        alt="product-thumbnail"
                                    />
                                </CardIcon>
                                <p className={classes.cardCategory}>Produto mais visto</p>
                                <h3 className={classes.cardTitle}>
                                    { mostViewedProduct.title_pt }
                                </h3>
                                <h5 className={classes.cardTitle}>
                                    visto { mostViewedProduct.views } vezes
                                </h5>
                            </CardHeader>
                        </Card >
                    </Fade>
                </Grid>
            </Grid>
            <Grid container spacing={4} className={classes.root}>
                <Paper className={classes.paper}>
                    <Grid item className={classes.cardContainer}>
                        <Typography style={{ color: "#8C9AA5" }}>
                            Produtos mais comprados
                        </Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ color: "#8C9AA5" }}>
                                        Imagem
                                    </TableCell>
                                    <TableCell style={{ color: "#8C9AA5" }}>
                                        Título
                                    </TableCell>
                                    <TableCell style={{ color: "#8C9AA5" }}>
                                        Comprado
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { mostBoughtProducts.map(product => (
                                    <TableRow key={product._id}>
                                        <TableCell style={{ color: "#8C9AA5" }}>
                                            <img 
                                                className={classes.productThumbnail}
                                                src={`${API_URL}/warehouse/${product.thumbnail.fileName}`} 
                                                alt={`thumbnail-${product._id}`}
                                            />
                                        </TableCell>
                                        <TableCell style={{ color: "#8C9AA5" }}>
                                            { product.title_pt }
                                        </TableCell>
                                        <TableCell style={{ color: "#8C9AA5" }}>
                                            { product.bought }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Paper>
            </Grid>
        </>
    )
}

export default compose(
    withContext,
    withStyles(styles)
)(Dashboard);