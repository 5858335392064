import React, { useState } from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import {
    Grid,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    Button,
    Paper,
    withStyles,
    FormHelperText,
    Tooltip
} from '@material-ui/core';
import { isInteger, isEmpty } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles';

const NutritionForm = ({
    classes,
    values,
    handleSubmit,
    setValues
}) => {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [newNutrientName, setNewNutrientName] = useState("");
    const [newNutrientValue, setNewNutrientValue] = useState("");
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    const handleToggleIndex = index => {
        if (isInteger(selectedIndex)) setSelectedIndex(null);
        else setSelectedIndex(index);
    }

    const handleValueChange = (e, index) => {
        values[index].dose = e.target.value;
        setValues(values);
    }
    
    const handleAddNutrient = () => {
        let errors = {};
        let touched = {};
        if (newNutrientName === "") {
            errors.newNutrientName = "Campo obrigatório";
            touched.newNutrientName = true;
        }

        if (newNutrientValue === "") {
            errors.newNutrientValue = "Campo obrigatório";
            touched.newNutrientValue = true;
        }

        if (!isEmpty(errors) && !isEmpty(touched)) {
            setErrors(errors);
            setTouched(touched);
            return;
        }

        setValues([...values, { nutrient: newNutrientName, dose: newNutrientValue }]);
    }

    const handleDeleteNutrient = index => {
        setValues(values.filter((v, i) => i !== index));
    }

    return (
        <form onSubmit={handleSubmit}>
            <Paper className={classes.paper}>
                <Grid container direction="column" spacing={2} justify="center" alignItems="center">
                    <Grid item>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Ações
                                    </TableCell>
                                    <TableCell>
                                        Nutriente
                                    </TableCell>
                                    <TableCell>
                                        Valor
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { values.map((value, index) => (
                                    <TableRow>
                                        <TableCell>
                                            <Tooltip title="Editar">
                                                <Button 
                                                    className={classes.tableButton}
                                                    onClick={() => handleToggleIndex(index)}
                                                    variant="contained" 
                                                    color="primary"
                                                >
                                                    { selectedIndex !== index && <EditIcon /> }
                                                    { selectedIndex === index && <CloseIcon /> }
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Apagar">
                                                <Button 
                                                    className={classes.tableButton}
                                                    onClick={() => handleDeleteNutrient(index)}
                                                    variant="contained" 
                                                    color="primary"
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            { value.nutrient }
                                        </TableCell>
                                        <TableCell>
                                            { selectedIndex !== index && value.dose }
                                            { selectedIndex === index && (
                                                <TextField 
                                                    label="Valor"
                                                    value={value.dose}
                                                    onChange={e => handleValueChange(e, index)}
                                                    fullWidth
                                                />
                                            ) }
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={3}>Adicionar nutriente</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Tooltip title="Adicionar">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleAddNutrient}
                                            >
                                                <AddIcon />
                                            </Button>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <TextField 
                                            label="Novo nutriente"
                                            value={newNutrientName}
                                            onChange={e => setNewNutrientName(e.target.value)}
                                            onFocus={() => setTouched({ ...touched, newNutrientName: true })}
                                            error={!!errors.newNutrientName}
                                        />
                                        { !!errors.newNutrientName && touched.newNutrientName && (
                                            <FormHelperText error>
                                                { errors.newNutrientName }
                                            </FormHelperText>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <TextField 
                                            label="Novo nutriente"
                                            value={newNutrientValue}
                                            onChange={e => setNewNutrientValue(e.target.value)}
                                            onFocus={() => setTouched({ ...touched, newNutrientValue: true })}
                                            error={!!errors.newNutrientValue}
                                        />
                                        { !!errors.newNutrientValue && touched.newNutrientValue && (
                                            <FormHelperText error>
                                                { errors.newNutrientValue }
                                            </FormHelperText>
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item>
                        <Button type="submit" variant="contained" color="primary">
                            Guardar nutrição
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </form>
    )
};

export default compose(
    withStyles(styles),
    withFormik({
        mapPropsToValues: ({ value }) => {
            if (value) {
                return value;
            }
            return []
        },
        handleSubmit: (values, { props: { handleNutritionChange }}) => 
            handleNutritionChange(values)
    })
)(NutritionForm);