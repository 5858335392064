import React from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import {
    Grid,
    TextField,
    FormHelperText,
    IconButton,
    withStyles,
    Paper,
    Dialog,
    DialogContent,
    Typography,
    DialogActions,
    Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { postOrder, putOrder } from '../../services/axios';
import { withContext } from '../../services/context';
import styles from './styles';
import OrderItemsInput from '../../components/OrderItemsInput';
import SubmitButton from '../../components/SubmitButton';

const OrderForm = ({
    classes,
    onClose,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    status,
    setStatus
}) => {
    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            <Dialog open={!!status.formResponseCode}>
                <DialogContent>
                    <Typography variant="h5">
                        { status.formResponseCode === 201 && "Categoria guardada com sucesso" }
                        { status.formResponseCode === 200 && "Categoria alterada com sucesso" }
                        { status.formResponseCode > 201 && "Erro no servidor. Tente mais tarde" }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => setStatus({ formResponseCode: null })}>Ok</Button>
                </DialogActions>
            </Dialog>
            <IconButton className={classes.button} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <Paper>
                <Grid container direction="column" spacing={2} justify="center" alignItems="center">
                    <Grid item style={{ width: "75%" }}>
                        <TextField 
                            name="email"
                            label="Email do cliente"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && errors.email}
                            fullWidth
                        />
                        { touched.email && errors.email && (
                            <FormHelperText error>
                                { errors.email }
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item style={{ width: "75%" }}>
                        <TextField 
                            name="shippingAddress.line1"
                            label="Rua"
                            value={values.shippingAddress?.line1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.shippingAddress?.line1 && errors.shippingAddress?.line1}
                            fullWidth
                        />
                        { touched.shippingAddress?.line1 && errors.shippingAddress?.line1 && (
                            <FormHelperText error>
                                { errors.shippingAddress?.line1 }
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item style={{ width: "75%" }}>
                        <TextField 
                            name="shippingAddress.line2"
                            label="Porta e Andar"
                            value={values.shippingAddress?.line2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.shippingAddress?.line2 && errors.shippingAddress?.line2}
                            fullWidth
                        />
                        { touched.shippingAddress?.line2 && errors.shippingAddress?.line2 && (
                            <FormHelperText error>
                                { errors.shippingAddress?.line2 }
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item style={{ width: "75%" }}>
                        <TextField 
                            name="shippingAddress.cep"
                            label="CP"
                            value={values.shippingAddress?.cep}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.shippingAddress?.cep && errors.shippingAddress?.cep}
                            fullWidth
                        />
                        { touched.shippingAddress?.cep && errors.shippingAddress?.cep && (
                            <FormHelperText error>
                                { errors.shippingAddress?.cep }
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item style={{ width: "75%" }}>
                        <TextField 
                            name="shippingAddress.city"
                            label="Cidade"
                            value={values.shippingAddress?.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.shippingAddress?.city && errors.shippingAddress?.city}
                            fullWidth
                        />
                        { touched.shippingAddress?.city && errors.shippingAddress?.city && (
                            <FormHelperText error>
                                { errors.shippingAddress?.city }
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item style={{ width: "75%" }}>
                        <TextField 
                            name="shippingAddress.state"
                            label="Distrito"
                            value={values.shippingAddress?.state}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.shippingAddress?.state && errors.shippingAddress?.state}
                            fullWidth
                        />
                        { touched.shippingAddress?.state && errors.shippingAddress?.state && (
                            <FormHelperText error>
                                { errors.shippingAddress?.state }
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item style={{ marginTop: "3rem" }}>
                        <OrderItemsInput
                            values={values.items} 
                            onChange={items => setFieldValue("items", items)}
                            onBlur={handleBlur}
                        />
                        { errors.items && touched.items && (
                            <FormHelperText error>
                                { errors.items }
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid container justify="center" alignItems="center">
                        <SubmitButton
                            variant="contained"
                            color="primary"
                            isSubmitting={isSubmitting}
                        >
                            Submeter
                        </SubmitButton>
                    </Grid>
                </Grid>
            </Paper>
        </form>
    );
}

export default compose(
    withContext,
    withStyles(styles),
    withFormik({
        mapPropsToStatus: () => ({ formResponseCode: null }),
        mapPropsToValues: ({ values }) => {
            if (values) {
                return {
                    _id: values._id,
                    email: values.user?.email,
                    items: values.items,
                    shippingAddress: values.shippingAddress
                }
            }
            return {
                email: null,
                items: [],
                shippingAddress: {
                    line1: "",
                    lines2: "",
                    cep: "",
                    city: "",
                    state: ""
                }
            }
        },
        handleSubmit: async (values, { props: { context: { dispatch } }, setSubmitting, setErrors, setStatus }) => {
            let response;
            const order = {
                _id: values._id,
                email: values.email,
                items: values.items.map(item => 
                    ({ quantity: item.quantity, product: item.product._id })
                ),
                shippingAddress: values.shippingAddress
            };

            try {
                if (!values._id) {
                    response = await postOrder(order);
                    dispatch({
                        type: "ADD_ORDER",
                        payload: response.data.content
                    });
                } else {
                    response = await putOrder(order);
                    dispatch({
                        type: "UPDATE_ORDER",
                        payload: response.data.content
                    })
                }
            } catch (e) {
                setErrors({
                    email: "Utilizador não encontrado"
                });
            }
            setStatus({ formResponseCode: response.data.status });
            setSubmitting(false);
        },
        validate: values => {
            const errors = {};

            if (!values.email) {
                errors.email = "Campo obrigatório";
            }

            if (values.items.length === 0) {
                errors.items = "Obrigatório pelo menos um produto";
            }

            return errors;
        }
    })
)(OrderForm);