import React from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import { Checkbox, FormControlLabel, Grid, IconButton, TextField, withStyles } from '@material-ui/core';
import styles from './styles';
import SubmitButton from '../SubmitButton';
import { withContext } from '../../services/context';
import { postSocial, putSocial, removeSocial } from '../../services/axios';
import { Delete } from '@material-ui/icons';

const SocialForm = ({ 
    classes,
    values,
    handleBlur,
    context: { dispatch },
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting
}) => {
    const deleteSocial = async id => {
        const response = await removeSocial(id);
        dispatch({
            type: "REMOVE_SOCIAL",
            payload: response.data.content._id
        })
    }

    return (
        <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container spacing={4} justify="center" alignItems="center">
                <Grid item className={classes.inputContainer}>
                    <TextField
                        name="type"
                        label="Tipo"
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.type && touched.type}
                        fullWidth
                    />
                </Grid>
                <Grid item className={classes.inputContainer}>
                    <TextField
                        name="url"
                        label="URL"
                        value={values.url}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.url && touched.url}
                        fullWidth
                    />
                </Grid>
                <Grid item className={classes.inputContainer}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={values.published}
                            onChange={handleChange}
                            name="published"
                            color="primary"
                        />
                        }
                        label="Publicado"
                    />
                </Grid>
                <Grid item className={classes.inputContainer}>
                    <SubmitButton variant="contained" color="primary" isSubmitting={isSubmitting}>
                        Guardar
                    </SubmitButton>
                </Grid>
                { values._id ? (
                    <Grid item className={classes.inputContainer}>
                        <IconButton onClick={() => deleteSocial(values._id)} color="primary" type="button">
                            <Delete />
                        </IconButton>
                    </Grid>
                ) : (
                    <Grid item className={classes.inputContainer} />
                )}
            </Grid>
        </form>
    )
}

export default compose(
    withContext,
    withStyles(styles),
    withFormik({
        mapPropsToValues: ({ values }) => {
            if (values) {
                return values;
            }
            return {
                type: "",
                url: "",
                published: false
            }
        },
        handleSubmit: async (values, { props: { context: { dispatch } } }) => {
            let response;
            if (!values._id) {
                response = await postSocial(values);
                dispatch({
                    type: "ADD_SOCIAL",
                    payload: response.data.content
                });
            } else {
                response = await putSocial(values);
                dispatch({
                    type: "UPDATE_SOCIAL",
                    payload: response.data.content
                })
            }
        }
    })
)(SocialForm);