import React, { useState } from 'react';
import { compose } from 'recompose'
import {
    Paper,
    Grid,
    FormLabel,
    Button,
    withStyles
} from '@material-ui/core';
import { Fade } from 'react-reveal';

import styles from './styles';
import { postFile, API_URL } from '../../services/axios';
import PropTypes from 'prop-types';

const ImageUpload = ({ classes, title, buttonText, value, onChange, width = 200, height = 200 }) => {
    const [thumbnailPreview, setThumbnailPreview] = useState(
        value ? `${API_URL}/warehouse/${value?.filename}` : null
    ); 

    const handleChange = async e => {
        const file = e.target.files[0] 
        const formData = new FormData();
        formData.append("file", file);
        const response = await postFile(formData);
        if (response.data.status === 201) {
            onChange(response.data.content);
            const blob = URL.createObjectURL(file);
            setThumbnailPreview(blob);
        }
    }

    return (
        <Paper className={classes.root}>
            <FormLabel>
                { title }
            </FormLabel>
            <Grid 
                container
                direction="column"
                justify="center" 
                alignItems="center"
                spacing={2}
            >
                <FormLabel htmlFor="file-input">
                    <Button 
                        component="span"
                        variant="contained"
                        color="primary"
                        className={classes.thumbnailButton}
                    >
                        { buttonText }
                    </Button>
                </FormLabel>
                <input
                    name="thumbnail"
                    accept="image/*"
                    onChange={handleChange}
                    style={{ display: 'none' }}
                    id="file-input"
                    type="file"
                />
                {
                    thumbnailPreview && 
                    (
                        <Fade>
                            <img
                                className={classes.thumbnail}
                                style={{ width, height }}
                                src={thumbnailPreview}
                                alt="thumbnail" 
                            />
                        </Fade>
                    )
                }
            </Grid>
        </Paper>
    );
}

ImageUpload.propTypes = {
    title: PropTypes.string,
    buttonText: PropTypes.string,
    onChange: PropTypes.func
}

export default compose(
    withStyles(styles)
)(ImageUpload);