import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import {
    Grid,
    withStyles,
    CircularProgress,
    Dialog,
    Tooltip,
} from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TimerIcon from '@material-ui/icons/Timer';
import LoopIcon from '@material-ui/icons/Loop';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import { Fade } from 'react-reveal';

import styles from './styles';
import { withContext } from '../../services/context';
import { fetchPayments } from '../../services/axios';
import Table from '../../components/Table';
import RowActions from '../../components/Table/RowActions';
import PaymentDetails from '../../components/PaymentDetails';

const Payments = ({ 
    classes,
    context: { payments, dispatch } 
}) => {
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [paymentSelected, setPaymentSelected] = useState(null);

    const handleDetailsOpen = () => setDetailsOpen(true);

    const handleDetailsClose = () => setDetailsOpen(false);

    const viewDetails = payment => {
        setPaymentSelected(payment);
        handleDetailsOpen();
    };

    const actions = {
        view: viewDetails,
    }

    const columns = [
        { title: 'Ações', render: row => <RowActions target={row} actions={actions} />},
        { title: 'ID', field: 'id', hidden: true },
        { title: 'Cliente', field: 'customer.email' },
        { title: 'Quantia', field: 'amount', render: row => `${(parseFloat(row.amount)/100).toFixed(2)} €` },
        { title: 'Estado', field: 'status', render: row => {
            switch (row.status) {
                case "requires_payment_method":
                    return (
                        <Tooltip title="À espera de método de pagamento">
                            <CreditCardIcon />
                        </Tooltip>
                    );
                case "requires_confirmation":
                    return (
                        <Tooltip title="À espera de confirmação">
                            <TimerIcon />
                        </Tooltip>
                    )
                case "processing":
                    return (
                        <Tooltip title="Processando">
                            <LoopIcon />
                        </Tooltip>
                    )
                case "canceled":
                    return (
                        <Tooltip title="Cancelado">
                            <CloseIcon />
                        </Tooltip>
                    )
                case "succeeded":
                    return (
                        <Tooltip title="Confirmado">
                            <CheckIcon />
                        </Tooltip>
                    )
                default: 
                    return (
                        <Tooltip title="Erro">
                            <ErrorIcon />
                        </Tooltip>
                    )
            }
        }}
    ]

    useEffect(() => {
        const getPayments = async () => {
            const response = await fetchPayments();
            dispatch({
                type: "SET_PAYMENTS",
                payload: response.data.content
            })
        }
        getPayments();
    }, [dispatch])
    
    if (!payments) {
        return (
            <Grid container justify="center" alignItems="center">
                <CircularProgress size={60} />
            </Grid>
        )
    }

    return (
        <Fade duration={400}>
            <div className={classes.root}>
                <Dialog
                    open={detailsOpen}
                    onClose={handleDetailsClose}
                    fullScreen
                >
                    <PaymentDetails payment={paymentSelected} onClose={handleDetailsClose} />
                </Dialog>
                <Grid container direction="column">
                    <Grid item>
                        <Table title="Pagamentos" columns={columns} data={payments} />
                    </Grid>
                </Grid>
            </div>
        </Fade>
    );
};

export default compose(
    withContext,
    withStyles(styles)
)(Payments);