import React from 'react';
import { compose } from 'recompose';
import { 
    MenuItem, 
    Input, 
    Select, 
    InputLabel, 
    FormControl,
    Chip,
    Typography,
    withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from './styles';

const SelectInput = ({ 
    classes, 
    values = [],
    options,
    onChange, 
    error,
    label,
    idExtractor = "_id", 
    labelExtractor = "title_pt",
    multiple = false
}) => {
    const handleChange = e => {
        if (multiple) {
            const newValues = options.filter(option => e.target.value.includes(option[idExtractor]));
            onChange(newValues);
        } else {
            onChange(options.find(option => option[idExtractor] === e.target.value));
        }
    };

    return (
        <FormControl variant="outlined" className={classes.selectInput} error={error}>
            <InputLabel>{ label }</InputLabel>
            <Select
                multiple={multiple}
                value={multiple ? values?.map(v => v[idExtractor]) : values[idExtractor] || values}
                onChange={handleChange}
                input={<Input />}      
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {multiple ? (
                            selected.map((value) => (
                                <Chip 
                                    key={ value }
                                    label={ values?.find(v => v[idExtractor] === value)[labelExtractor] || value }
                                    className={classes.chip}
                                />
                            ))
                            ) : (
                                <Typography className={classes.chip}>
                                    { values[labelExtractor] || selected }
                                </Typography>
                            )
                        }
                    </div>
                )}
            >
                {
                    options.map(option => (
                        <MenuItem key={option[idExtractor] || option} value={multiple ? option?.[idExtractor] : option?.[idExtractor] || option}>
                            { option[labelExtractor] || option }
                        </MenuItem>
                    ))
                }
                { options.length === 0 && (
                    <MenuItem disabled>
                        (Vazio)
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    )
}

SelectInput.propTypes = {
    label: PropTypes.string.isRequired,
    values: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string,
    ]),
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired
}

export default compose(
    withStyles(styles)
)(SelectInput);