import React from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import {
    Typography,
    Grid,
    withStyles,
    TextField,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { withContext } from '../../services/context';
import styles from './styles';
import SubmitButton from '../SubmitButton';
import { postBlogCategory, putBlogCategory } from '../../services/axios';

const CategoryForm = ({
    classes,
    label,
    onClose,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
    status,
    setStatus
}) => {
    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Dialog open={!!status.formResponseCode}>
                <DialogContent>
                    <Typography variant="h5">
                        { status.formResponseCode === 201 && "Categoria guardada com sucesso" }
                        { status.formResponseCode === 200 && "Categoria alterada com sucesso" }
                        { status.formResponseCode > 201 && "Erro no servidor. Tente mais tarde" }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => setStatus({ formResponseCode: null })}>Ok</Button>
                </DialogActions>
            </Dialog>
            <IconButton className={classes.button} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <Grid container direction="column" spacing={4} justify="center">
                <Typography variant="h5" color="primary">
                    { label }
                </Typography>
                <Grid item>
                    <TextField 
                        name="title_pt"
                        label="Nome em português"
                        value={values.title_pt}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.title_pt && touched.title_pt}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField 
                        name="title_en"
                        label="Nome em inglês"
                        value={values.title_en}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.title_en && touched.title_en}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <Grid container justify="center">
                        <SubmitButton 
                            variant="contained"
                            color="primary"
                            isSubmitting={isSubmitting}
                        >
                            Submeter
                        </SubmitButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default compose(
    withContext,
    withStyles(styles),
    withFormik({
        mapPropsToStatus: () => ({ formResponseCode: null }),
        mapPropsToValues: ({ values }) => {
            if (values) {
                return values;
            }
            return {
                title_pt: "",
                title_en: ""
            }
        },
        handleSubmit: async (values, { props: { context: { dispatch } }, setSubmitting, setStatus }) => {
            let response;
            if (values._id) {
                response = await putBlogCategory(values);
            } else {
                response = await postBlogCategory(values);
            }
            dispatch({
                type: "UPDATE_CATEGORY",
                payload: response.data.content
            })
            setStatus({ formResponseCode: response.data.status });
            setSubmitting(false);
        } 
    })
)(CategoryForm);