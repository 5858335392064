export default theme => ({
    root: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        background: theme.palette.primary.main,
        backgroundSize: "cover",
        backgroundPosition: "center"
    },
    container: {
        paddingTop: "10%"
    },
    paper: {
        padding: 50,
    }
});