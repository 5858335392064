import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import AssessmentIcon from '@material-ui/icons/Assessment';
import StoreIcon from '@material-ui/icons/Store';
import CategoryIcon from '@material-ui/icons/Category';
import HelpIcon from '@material-ui/icons/Help';
import BookIcon from '@material-ui/icons/Book';
import FilterIcon from '@material-ui/icons/Filter';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import EuroIcon from '@material-ui/icons/Euro';
import SettingsIcon from '@material-ui/icons/Settings';
import EmailIcon from '@material-ui/icons/Email';
import DnsIcon from '@material-ui/icons/Dns';
import PagesIcon from '@material-ui/icons/Pages';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import TocIcon from '@material-ui/icons/Toc';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';

import { withContext } from '../context';
import { sessionIsExpired } from '../../services/storage';
import Login from '../../screens/Login';
import Dashboard from '../../screens/Dashboard';
import Products from '../../screens/Products';
import Categories from '../../screens/Categories';
import Help from '../../screens/Help';
import BlogPosts from '../../screens/BlogPosts';
import BlogCategories from '../../screens/BlogCategories';
import Orders from '../../screens/Orders';
import Payments from '../../screens/Payments';
import SiteSettings from '../../screens/SiteSettings';
import Newsletter from '../../screens/Newsletter';
import ContactInfo from '../../screens/ContactInfo';
import PageList from '../../screens/PageList';
import SEO from '../../screens/SEO';
import Banners from '../../screens/Banners';
import PromoCodes from '../../screens/PromoCodes';

export const routes = [
    {
        path: '/',
        exact: true,
        component: Dashboard,
        label: "Dashboard",
        icon: AssessmentIcon
    },
    {
        path: '/orders',
        exact: true,
        component: Orders,
        label: "Encomendas",
        icon: BusinessCenterIcon
    },
    {
        path: '/payments',
        exact: true,
        component: Payments,
        label: "Pagamentos",
        icon: EuroIcon
    },
    {
        path: '/newsletter',
        exact: true,
        component: Newsletter,
        label: "Newsletter",
        icon: EmailIcon
    },
    {
        path: '/products',
        exact: true,
        component: Products,
        label: "Produtos",
        icon: StoreIcon
    },
    {
        path: '/product-categories',
        exact: true,
        component: Categories,
        label: "Categorias de produto",
        icon: CategoryIcon
    },
    {
        path: '/posts',
        exact: true,
        component: BlogPosts,
        label: "Publicações",
        icon: BookIcon
    },
    {
        path: '/blog-categories',
        exact: true,
        component: BlogCategories,
        label: "Categorias de blog",
        icon: FilterIcon
    },
    {
        path: '/help',
        exact: true,
        component: Help,
        label: "Ajuda",
        icon: HelpIcon
    },
    {
        path: '/contact-info',
        exact: true,
        component: ContactInfo,
        label: "Contactos",
        icon: DnsIcon
    },
    {
        path: '/banners',
        exact: true,
        component: Banners,
        label: "Banners",
        icon: PagesIcon
    },
    {
        path: '/page-list',
        exact: true,
        component: PageList,
        label: "Conteúdo",
        icon: TocIcon
    },
    {
        path: '/promo-codes',
        exact: true,
        component: PromoCodes,
        label: "Códigos Promocionais",
        icon: ViewWeekIcon
    },
    {
        path: '/settings',
        exact: true,
        component: SiteSettings,
        label: "Configurações",
        icon: SettingsIcon
    },
    {
        path: '/seo',
        exact: true,
        component: SEO,
        label: "SEO",
        icon: SubtitlesIcon
    }
]

export const PrivateRoute = withContext(
    ({ 
        context: { user, dispatch }, 
        component: Component,
        match,
        ...rest 
    }) => {
        useEffect(() => {
            const tokenValid = async () => {
                const userData = await sessionIsExpired();
                if (userData) {
                    dispatch({
                        type: "LOGIN_USER",
                        payload: userData
                    });
                }
            }
            tokenValid();
        }, [dispatch]);

        if (!user.isAuthed) {
            return <Login status={match?.params?.status} />
        }

        return (
            <Route {...rest} component={Component} />
        )
    }
);