import React from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import {
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    withStyles
} from '@material-ui/core';

import { withContext } from '../../services/context';
import { putProduct } from '../../services/axios';
import styles from './styles';
import SubmitButton from '../SubmitButton';

const EditStatusModal = ({
    classes,
    product,
    values,
    handleChange,
    handleSubmit,
    isSubmitting
}) => {
    return (
        <form onSubmit={handleSubmit} class={classes.formContainer}>
            <Grid container direction="column" spacing={4} justify="center" alignItems="center">
                <Typography variant="h5">
                    Editar estados de { product?.title_pt }
                </Typography>
                <Grid item className={classes.input}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={values.news}
                            onChange={handleChange}
                            name="news"
                            color="primary"
                        />
                        }
                        label="Novidade"
                    />
                </Grid>
                <Grid item className={classes.input}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={values.promoted}
                            onChange={handleChange}
                            name="promoted"
                            color="primary"
                        />
                        }
                        label="Promoção"
                    />
                </Grid>
                <Grid item className={classes.input}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={values.featured}
                            onChange={handleChange}
                            name="featured"
                            color="primary"
                        />
                        }
                        label="Destaque"
                    />
                </Grid>
                <Grid item>
                    <SubmitButton variant="contained" color="primary" isSubmitting={isSubmitting}>
                        Submeter
                    </SubmitButton>
                </Grid>
            </Grid>
        </form>
    );
};

export default compose(
    withContext,
    withStyles(styles),
    withFormik({
        mapPropsToValues: ({ product }) => ({
            _id: product?._id,
            news: product?.news,
            promoted: product?.promoted,
            featured: product?.featured
        }),
        handleSubmit: async (values, { props: { context: { dispatch } }}) => {
            const response = await putProduct(values);

            dispatch({
                type: "UPDATE_PRODUCT",
                payload: response.data.content
            })
        }
    })
)(EditStatusModal);

