import React, { useEffect } from 'react';
import { compose } from 'recompose';
import {
    Grid,
    CircularProgress,
} from '@material-ui/core';

import { withContext } from '../../services/context';
import { fetchContactInfo } from '../../services/axios';
import ContactInfoForm from '../../components/ContactInfoForm';

const ContactInfo = ({
    context: {
        contactInfo,
        dispatch
    }
}) => {
    useEffect(() => {
        const getContactInfo = async () => {
            const response = await fetchContactInfo();
            dispatch({
                type: "SET_CONTACT_INFO",
                payload: response.data.content
            });
        }
        getContactInfo();
    }, [dispatch]);

    if (!contactInfo) {
        return (
            <Grid container justify="center">
                <CircularProgress size={60} />
            </Grid>
        )
    }

    return (
        <ContactInfoForm contactInfo={contactInfo} />
    );
}

export default compose(
    withContext
)(ContactInfo);