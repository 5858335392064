import React from 'react';
import { Router } from 'react-router-dom';
import { Grid, MuiThemeProvider } from '@material-ui/core';

import theme from './theme';
import { COLOR_QUINARY } from './theme/colors';
import history from './services/history';
import { Provider } from './services/context';
import { routes, PrivateRoute } from './services/router';
import Sidebar from "./components/Sidebar";

const App = () => {
    document.body.style.backgroundColor = COLOR_QUINARY;
    return (
        <MuiThemeProvider theme={theme}>
            <Provider>
                <Grid container>
                    <Grid item>
                        <Sidebar />
                    </Grid>
                    <Grid item style={{ flex: 1 }}>
                        <Router history={history}>
                            { routes.map(route => <PrivateRoute key={route.path}{...route} />) }
                        </Router>
                    </Grid>
                </Grid>
            </Provider>
        </MuiThemeProvider>
    )
}

export default App;