import React, { useState } from 'react';
import {
    Dialog,
} from '@material-ui/core';
import Fade from 'react-reveal';

import ConfirmationModelContent from './ConfirmationModalContent';

const ConfirmationModal = ({ open, handleClose, title, text, action }) => {
    const [confirmed, setConfirmed] = useState(false);
    const [error, setError] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    
    const handleAction = async () => {
        setSubmitting(true);
        try {
            await action();
        } catch (e) {
            setError(true);
        }
        setConfirmed(true);
        setSubmitting(false);
    }

    const closeConfirmationModal = () => {
        handleClose();
        setConfirmed(false);
        setError(false);
    }

    return (
        <Dialog 
            open={open} 
            onClose={closeConfirmationModal}
        >
            <Fade>
                <ConfirmationModelContent
                    title={title}
                    text={text}
                    confirmed={confirmed}
                    error={error}
                    isSubmitting={isSubmitting}
                    handleAction={handleAction}
                    handleClose={closeConfirmationModal}
                />
            </Fade>
        </Dialog>
    )
}

export default ConfirmationModal;