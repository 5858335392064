import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import {
    Grid,
    Button,
    Dialog,
    withStyles,
    CircularProgress
} from '@material-ui/core';
import { Fade } from 'react-reveal';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

import { fetchHelps } from '../../services/axios';
import { withContext } from '../../services/context';
import { updatePublishHelp, deleteHelp } from '../../services/axios';
import styles from './styles';
import Table from '../../components/Table';
import HelpForm from '../../components/HelpForm';
import RowActions from '../../components/Table/RowActions';
import ConfirmationModal from '../../components/ConfirmationModal';

const Help = ({ classes, context: { helps, dispatch } }) => {
    const [formOpen, setFormOpen] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationProps, setConfirmationProps] = useState(null);
    const [selectedHelp, setSelectedHelp] = useState(null);

    const handleFormOpen = () => setFormOpen(true);

    const handleFormClose = () => setFormOpen(false);

    const handleConfirmationOpen = () => setConfirmationOpen(true);

    const handleConfirmationClose = () => setConfirmationOpen(false);

    useEffect(() => {
        const getHelps = async () => {
            const response = await fetchHelps();
            dispatch({
                type: "SET_HELPS",
                payload: response.data.content
            })
        }
        getHelps()
    }, [dispatch]);

    const editHelp = help => {
        setSelectedHelp(help);
        handleFormOpen();
    }

    const publishHelp = help => {
        setConfirmationProps({
            title: "Publicar ajuda",
            text: `Deseja publicar a ajuda ${help.title_pt}?`,
            action: async () => {
                const response = await updatePublishHelp(help._id, !help.published);
                dispatch({
                    type: "UPDATE_HELPS",
                    payload: response.data.content
                })
            }
        })
        handleConfirmationOpen();
    }

    const removeHelp = help => {
        setConfirmationProps({
            title: "Apagar produto",
            text: `Deseja apagar o produto ${help.title_pt}?`,
            action: async () => {
                const response = await deleteHelp(help._id);
                dispatch({
                    type: "DELETE_HELPS",
                    payload: response.data.content
                })
            }
        });
        handleConfirmationOpen();
    }

    const actions = {
        edit: editHelp,
        publish: publishHelp,
        delete: removeHelp
    }

    const columns = [
        { title: 'Ações', render: row => <RowActions target={row} actions={actions} />},
        { title: 'ID', field: '_id', hidden: true },
        { title: 'Título (PT)', field: 'title_pt' },
        { title: 'Título (EN)', field: 'title_en' },
        { title: 'Criado', field: 'createdAt', render: row => (
            moment(new Date(row.createdAt)).format("YYYY-MM-DD HH:mm:ss")
        ) },
        { title: 'Publicado', field: 'published', render: row => (
            row.published ? <CheckIcon /> : <CloseIcon />
        ) }
    ]

    if (!helps) {
        return (
            <div className={classes.root}>
                <Grid container justify="center" alignItems="center">
                    <CircularProgress size={60} />
                </Grid>
            </div>
        )
    }

    return (
        <Fade duration={400}>
            <div className={classes.root}>
                <ConfirmationModal 
                    open={confirmationOpen}
                    handleClose={handleConfirmationClose}
                    {...confirmationProps}
                />
                <Dialog 
                    open={formOpen}
                    onClose={handleFormClose} 
                    fullScreen
                >
                    <HelpForm values={selectedHelp} onClose={handleFormClose}/>
                </Dialog>
                <Grid container direction="column">
                    <Grid item>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={handleFormOpen}
                        >
                            Adicionar ajuda
                        </Button>
                    </Grid>
                    <Grid item>
                        <Table title="Ajuda" columns={columns} data={helps} />
                    </Grid>
                </Grid>
            </div>
        </Fade>
    )
}

export default compose(
    withContext,
    withStyles(styles),
)(Help);