import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import { 
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Fade,
    FormHelperText,
    Grid, 
    IconButton, 
    Paper, 
    TextField, 
    Typography, 
    withStyles 
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { withContext } from '../../services/context';
import styles from './styles';
import SubmitButton from '../SubmitButton';
import ImageUpload from '../ImageUpload';
import SelectInput from '../SelectInput';
import { 
    fetchBlog, 
    fetchBlogCategories, 
    fetchProductVariants, 
    fetchProducts,
    putBanner,
    postBanner
} from '../../services/axios';
import { Autocomplete } from '@material-ui/lab';

const BannerForm = ({ 
    classes,
    context: { dispatch },
    label, 
    values,
    errors,
    touched,
    handleChange, 
    handleBlur, 
    handleSubmit, 
    onClose, 
    isSubmitting,
    setFieldValue,
    status,
    setStatus
}) => {
    const [refOptions, setRefOptions] = useState(null);
    const [loadingRef, setLoadingRef] = useState(false);

    useEffect(() => {
        const getRefOptionsAndValue = async () => {
            setLoadingRef(true);
            setRefOptions(null);
            let response = null;
            switch (values.type.value) {
                case "variants":
                    response = await fetchProductVariants();
                    dispatch({
                        type: "SET_PRODUCT_VARIANTS",
                        payload: response.data.content
                    });
                    break;
                case "categories":
                    response = await fetchBlogCategories();
                    dispatch({
                        type: "SET_CATEGORIES",
                        payload: response.data.content
                    });
                    break;
                case "blog":
                    response = await fetchBlog();
                    response.data.content = response.data.content.results;
                    dispatch({
                        type: "SET_POSTS",
                        payload: response.data.content
                    });
                    break;
                case "products":
                    response = await fetchProducts();
                    response.data.content = response.data.content.results;
                    dispatch({
                        type: "SET_PRODUCTS",
                        payload: response.data.content
                    });
                    break;
                default:
                    return
            }

            setRefOptions(response.data.content);
            setFieldValue('reference', response.data.content.find(value => value._id === values.reference || value._id === values.reference?._id));
            setLoadingRef(false);
        }
        getRefOptionsAndValue();
    }, [dispatch, setFieldValue, values.type]);

    const handleThumbnailChange = async value => setFieldValue("image", value);

    const handleReferenceChange = reference => {
        setFieldValue("reference", reference);
    }

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <div className={classes.root}>
                <Dialog open={!!status.formResponseCode}>
                    <DialogContent>
                        <Typography variant="h5">
                            { status.formResponseCode === 201 && "Banner guardado com sucesso" }
                            { status.formResponseCode === 200 && "Banner alterado com sucesso" }
                            { status.formResponseCode > 201 && "Erro no servidor. Tente mais tarde" }
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={() => setStatus({ formResponseCode: null })}>Ok</Button>
                    </DialogActions>
                </Dialog>
                <IconButton className={classes.button} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <Paper>
                    <Grid container direction="column" spacing={4} justify="center">
                        <Typography variant="h5" color="primary">
                            { label }
                        </Typography>
                        <Grid item>
                            <Grid container spacing={4}>
                                <Grid item style={{ flex: 1 }}>
                                    <TextField
                                        name="title_pt"
                                        label="Título em português"
                                        value={values.title_pt}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.title_pt && touched.title_pt}
                                        fullWidth
                                    />
                                    { !!errors.title_pt && touched.title_pt && (
                                        <Fade duration="200">
                                            <FormHelperText error>
                                                { errors.title_pt }
                                            </FormHelperText>
                                        </Fade>
                                    )}
                                </Grid>
                                <Grid item style={{ flex: 1 }}>
                                    <TextField
                                        name="title_en"
                                        label="Título em inglês"
                                        value={values.title_en}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.title_en && touched.title_en}
                                        fullWidth
                                    />
                                    { !!errors.title_en && touched.title_en && (
                                        <Fade duration="200">
                                            <FormHelperText error>
                                                { errors.title_en }
                                            </FormHelperText>
                                        </Fade>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={4}>
                                <Grid item style={{ flex: 1 }}>
                                    <TextField
                                        name="subtitle_pt"
                                        label="Subtítulo em português"
                                        value={values.subtitle_pt}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.subtitle_pt && touched.subtitle_pt}
                                        fullWidth
                                    />
                                    { !!errors.subtitle_pt && touched.subtitle_pt && (
                                        <Fade duration="200">
                                            <FormHelperText error>
                                                { errors.subtitle_pt }
                                            </FormHelperText>
                                        </Fade>
                                    )}
                                </Grid>
                                <Grid item style={{ flex: 1 }}>
                                    <TextField
                                        name="subtitle_en"
                                        label="Subtítulo em inglês"
                                        value={values.subtitle_en}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.subtitle_en && touched.subtitle_en}
                                        fullWidth
                                    />
                                    { !!errors.subtitle_en && touched.subtitle_en && (
                                        <Fade duration="200">
                                            <FormHelperText error>
                                                { errors.subtitle_en }
                                            </FormHelperText>
                                        </Fade>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={{ flex: 1 }}>
                            <ImageUpload
                                title="Imagem"
                                buttonText="Selecione uma imagem"
                                value={values.image}
                                onChange={handleThumbnailChange}
                                width={500}
                                height={350}
                            />
                        </Grid>
                        <Grid item className={classes.input}>
                            <Grid container spacing={4}>
                                <Grid item style={{ flex: 1 }}>
                                    <SelectInput
                                        values={values.placement}
                                        label="Local"
                                        options={[
                                            { label: "(Vazio)", value: "" },
                                            { label: "Carrossel", value: "carousel" },
                                            { label: "Separador", value: "separator" },
                                        ]}
                                        onChange={placement => setFieldValue("placement", placement)}
                                        labelExtractor="label"
                                        idExtractor="label"
                                        error={!!errors.placement && touched.placement}
                                    />
                                    { !!errors.placement && touched.placement && (
                                        <Fade duration="200">
                                            <FormHelperText error>
                                                { errors.placement }
                                            </FormHelperText>
                                        </Fade>
                                    )}
                                </Grid>
                                <Grid item style={{ flex: 1 }}>
                                    <SelectInput
                                        values={values.type}
                                        label="Tipo"
                                        options={[
                                            { label: "(Vazio)", value: "" },
                                            { label: "Categoria de produto", value: "variants" },
                                            { label: "Categoria de blog", value: "categories" },
                                            { label: "Publicação", value: "blog" },
                                            { label: "Produto", value: "products" }
                                        ]}
                                        onChange={type => setFieldValue("type", type)}
                                        labelExtractor="label"
                                        idExtractor="label"
                                        error={!!errors.type && touched.type}
                                    />
                                    { !!errors.type && touched.type && (
                                        <Fade duration="200">
                                            <FormHelperText error>
                                                { errors.type }
                                            </FormHelperText>
                                        </Fade>
                                    )}
                                </Grid>
                                <Grid item style={{ flex: 1, margin: 'auto' }}>
                                    { refOptions ? (
                                        <>
                                            <Autocomplete
                                                id="ref-select"
                                                value={values.reference}
                                                options={refOptions}
                                                getOptionLabel={(option) => option.title_pt}
                                                getOptionSelected={(option, value) => option?._id === value?._id}
                                                onChange={(e, values) => handleReferenceChange(values)}
                                                style={{ width: "100%" }}
                                                renderInput={(params) => <TextField {...params} label="Referência" variant="outlined" />}
                                            />
                                            { !!errors.reference && touched.reference && (
                                                <Fade duration="200">
                                                    <FormHelperText error>
                                                        { errors.reference }
                                                    </FormHelperText>
                                                </Fade>
                                            )}
                                        </>
                                    ) : (
                                        <div>
                                            {loadingRef ? (
                                                <div style={{ textAlign: 'center' }}>
                                                    <CircularProgress size={24} />
                                                </div>
                                            ):(
                                                <div style={{ width: '100%', margin: 'auto' }}>
                                                    <Typography>Selecione um tipo</Typography>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container justify="center">
                                <SubmitButton
                                    variant="contained"
                                    color="primary"
                                    isSubmitting={isSubmitting}
                                >
                                    Submeter
                                </SubmitButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </form>
    );
}

export default compose(
    withContext,
    withStyles(styles),
    withFormik({
        enableReinitialize: true,
        mapPropsToStatus: () => ({ formResponseCode: null }),
        mapPropsToValues: ({ values }) => {
            let typeValue = "";
            switch (values?.type) {
                case "variants":
                    typeValue = { label: "Categoria de produto", value: values?.type }
                    break;
                case "categories":
                    typeValue = { label: "Categoria de blog", value: values?.type }
                    break;
                case "blog":
                    typeValue = { label: "Publicação", value: values?.type }
                    break;
                case "products":
                    typeValue = { label: "Produto", value: values?.type }
                    break;
                default:
                    typeValue = { label: "(Vazio)", value: "" }

            }

            let placementValue = "";
            switch (values?.placement) {
                case "carousel":
                    placementValue = { label: "Carrossel", value: values?.placement }
                    break;
                case "separator":
                    placementValue = { label: "Separador", value: values?.placement }
                    break;
                default:
                    placementValue = { label: "(Vazio)", value: "" }
            }

            let formValues = {}

            if (values?._id) {
                formValues = {
                    _id: values._id,
                    title_pt: values.title_pt,
                    title_en: values.title_en,
                    subtitle_pt: values.subtitle_pt,
                    subtitle_en: values.subtitle_en,
                    image: values.image,
                    reference: values.reference,
                    type: typeValue,
                    placement: placementValue
                }
            } else {
                formValues = {
                    title_pt: "",
                    title_en: "",
                    subtitle_pt: "",
                    subtitle_en: "",
                    image: null,
                    reference: null,
                    type: typeValue,
                    placement: placementValue
                }
            }

            return formValues;
        },
        handleSubmit: async (values, { props: { context: { dispatch } }, setSubmitting, setStatus }) => {
            let response;
            values = {
                ...values,
                type: values.type.value,
                placement: values.placement.value,
                reference: values.reference._id
            };
            if (values._id) {
                response = await putBanner(values);
                dispatch({
                    type: "UPDATE_BANNER",
                    payload: response.data.content
                })
            } else {
                response = await postBanner(values);
                dispatch({
                    type: "UPDATE_BANNER",
                    payload: response.data.content
                })
            }
            setStatus({ formResponseCode: response.data.status });
            setSubmitting(false);
        },
        validate: values => {
            let errors = {};

            if (!values.title_pt) errors = { ...errors, title_pt: "Este campo é obrigatório" }
            if (!values.subtitle_pt) errors = { ...errors, subtitle_pt: "Este campo é obrigatório" }
            if (!values.reference) errors = { ...errors, reference: "Este campo é obrigatório" }
            if (!values.type) errors = { ...errors, type: "Este campo é obrigatório" }
            if (!values.placement) errors = { ...errors, placement: "Este campo é obrigatório" }

            return errors;
        }
    })
)(BannerForm);