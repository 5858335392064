import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

const SubmitButton = ({ children, isSubmitting, ...rest }) => {

    const renderButtonContent = () => {
        if (isSubmitting) {
            return (
                <CircularProgress size={24} color="secondary" />
            )
        }
        return children
    }
    
    return (
        <Button type="submit" {...rest}>
            { renderButtonContent() }
        </Button>
    )
};

SubmitButton.propTypes = {
    isSubmitting: PropTypes.bool,
    children: PropTypes.string
}

export default SubmitButton;