import { FormLabel } from "@material-ui/core";
import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";


const CustomToolbar = ({ id }) => (
  <div id={`toolbar-${id}`}>
    <select className="ql-font">
      <option value="arial" selected>Arial</option>
      <option value="comic-sans">Comic Sans</option>
      <option value="courier-new">Courier New</option>
      <option value="georgia">Georgia</option>
      <option value="helvetica">Helvetica</option>
      <option value="lucida">Lucida</option>
    </select>
    <select className="ql-size">
      <option value="extra-small">Size 1</option>
      <option value="small">Size 2</option>
      <option value="medium">Size 3</option>
      <option value="large" selected>Size 4</option>
    </select>
    <select className="ql-align" />
    <select className="ql-color" />
    <select className="ql-background" />
    <button className="ql-clean" />
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" />
  </div>
);

const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida"
];
Quill.register(Font, true);

class TextEditor extends React.Component {
    handleChange = html => {
      this.props.onChange(this.props.name, html);
    };

    static formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color"
    ];

    static modules = (toolbarId) => ({
        toolbar: {
          container: "#toolbar-" + toolbarId,
        }
      })

    render() {
        return (
            <div className="text-editor">
                <FormLabel>
                    { this.props.label }
                </FormLabel>
                <CustomToolbar id={this.props.name}/>
                <ReactQuill
                    ref={(el) => { this.quillRef = el }}
                    style={{ height: "200px", marginBottom: "30px" }}
                    value={this.props.value || ""}
                    onChange={this.handleChange}
                    placeholder={this.props.placeholder}
                    modules={TextEditor.modules(this.props.name)}
                    formats={TextEditor.formats}
                />
            </div>
        );
    }
}

export default TextEditor;
