import React from 'react';
import { compose } from 'recompose';
import { 
    Container,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { Fade } from 'react-reveal';
import NumberFormat from 'react-number-format';

import { API_URL } from '../../services/axios';
import styles from './styles';

const OrderDetails = ({
    order,
    classes,
    onClose
}) => {
    return (
        <Fade duration={400}>
            <Container className={classes.root}>
                <IconButton className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <Typography className={classes.mainTitle} variant="h4">
                    Encomenda {order._id}
                </Typography>
                <Grid 
                    container
                    spacing={4}
                    direction="column"
                >
                    <Grid item className={classes.userSection}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={4}>
                                <Grid item style={{ flex: 1 }}>
                                    <Grid container direction="column" spacing={4}>
                                        <Typography variant="h5">
                                            Informações gerais
                                        </Typography>
                                        <Grid item className={classes.section}>
                                            <TextField 
                                                label="Primeiro e último nome"
                                                value={`${order.user?.firstName} ${order.user?.lastName}`}
                                                disabled
                                                fullWidth
                                                InputProps={{
                                                    style: { color: "black" }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item className={classes.section}>
                                            <TextField 
                                                label="Email"
                                                value={order.user?.email}
                                                disabled
                                                fullWidth
                                                InputProps={{
                                                    style: { color: "black" }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item className={classes.section}>
                                            <TextField 
                                                label="NIF"
                                                value={order.user?.nif}
                                                disabled
                                                fullWidth
                                                InputProps={{
                                                    style: { color: "black" }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField 
                                                label="Sexo"
                                                value={order.user?.gender}
                                                disabled
                                                fullWidth
                                                InputProps={{
                                                    style: { color: "black" }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField 
                                                label="Membro deste"
                                                value={moment(order.user?.createdAt).format("DD-MM-YYYY")}
                                                disabled
                                                fullWidth
                                                InputProps={{
                                                    style: { color: "black" }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={{ flex: 1 }}>
                                    <Grid container direction="column" spacing={4}>
                                        <Typography variant="h5">
                                            Morada
                                        </Typography>
                                        <Grid item>
                                            <TextField 
                                                label="Rua"
                                                value={order.shippingAddress?.line1}
                                                disabled
                                                fullWidth
                                                InputProps={{
                                                    style: { color: "black" }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField 
                                                label="Porta e Andar"
                                                value={order.shippingAddress?.line2}
                                                disabled
                                                fullWidth
                                                InputProps={{
                                                    style: { color: "black" }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField 
                                                label="CP"
                                                value={order.shippingAddress?.cep}
                                                disabled
                                                fullWidth
                                                InputProps={{
                                                    style: { color: "black" }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField 
                                                label="Cidade"
                                                value={order.shippingAddress?.city}
                                                disabled
                                                fullWidth
                                                InputProps={{
                                                    style: { color: "black" }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField 
                                                label="Distrito"
                                                value={order.shippingAddress?.state}
                                                disabled
                                                fullWidth
                                                InputProps={{
                                                    style: { color: "black" }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item className={classes.itemSection}>
                        <Paper className={classes.paper}>
                            <Grid container direction="column" spacing={4}>
                                <Typography variant="h5">
                                    Informações dos produtos
                                </Typography>
                                <Grid item>
                                    { order.items.map(item => {
                                        return (
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Grid container justify="space-between">
                                                        <Grid item>
                                                            <Typography variant="body1">
                                                                { item.quantity } x {item.product?.title_pt}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <NumberFormat 
                                                                decimalScale={2}
                                                                displayType="text"
                                                                decimalSeparator="."
                                                                value={ (parseFloat(item.product?.price) * parseInt(item.quantity)) }
                                                                suffix="€"
                                                                renderText={value => (
                                                                    <Typography variant="body1" align="right">
                                                                        { value }
                                                                    </Typography>
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={4} justify="space-around" alignItems="center">
                                                        <Grid item>
                                                            <Typography>
                                                                Thumbnail
                                                            </Typography>
                                                            <img 
                                                                className={classes.thumbnail}
                                                                src={`${API_URL}/warehouse/${item.product?.thumbnail?.fileName}`} 
                                                                alt="thumbnail"
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container spacing={4} direction="column">
                                                                <Grid item className={classes.section}>
                                                                    <TextField 
                                                                        label="Produto"
                                                                        value={item.product?.title_pt}
                                                                        disabled
                                                                        fullWidth
                                                                        InputProps={{
                                                                            style: { color: 'black' }
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item className={classes.section}>
                                                                    <TextField 
                                                                        label="Preço"
                                                                        value={item.product?.price}
                                                                        disabled
                                                                        fullWidth
                                                                        InputProps={{
                                                                            style: { color: 'black' }
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item className={classes.section}>
                                                                    <TextField 
                                                                        label="Stock"
                                                                        value={item.product?.stock}
                                                                        disabled
                                                                        fullWidth
                                                                        InputProps={{
                                                                            style: { color: 'black' }
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        )
                                    }) }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Fade>
    )
}

export default compose(
    withStyles(styles)
)(OrderDetails);