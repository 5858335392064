import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Button,
    Tooltip,
    withStyles
} from '@material-ui/core';
import { Fade } from 'react-reveal';
import DeleteIcon from '@material-ui/icons/Close';
import { useDropzone } from 'react-dropzone';

import { API_URL, getFile, postFile } from '../../services/axios';
import styles from './styles';

const Dropzone = ({ classes, values, handleAdd, handleRemove }) => {
    const [files, setFiles] = useState(values?.map(value => ({
        ...value,
        preview: `${API_URL}/warehouse/${value?.filename}`
    })) || []);
    const [deleteEnabled, setDeleteEnabled] = useState(null);

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',

        onDrop: async acceptedFiles => {
            const files = await Promise.all(
                acceptedFiles.map(async file => {
                    const formData = new FormData();
                    formData.append('file', file);
                    const response = await postFile(formData);
                    const fileResponse = await getFile(response.data.content);
                    if (response.data.status === 201) {
                        const newFile = {
                            ...file,
                            preview: `${API_URL}/warehouse/${fileResponse.data.content.filename}`,
                            _id: response.data.content
                        };
                        setFiles(files => [...files, newFile]);
                        return newFile;
                    }
                })
            )
            handleAdd(files.map(file => file._id));
        }
    });

    const enableDelete = index => setDeleteEnabled(index);

    const disableDelete = () => setDeleteEnabled(null);

    const removeImage = index => {
        const newFiles = files.filter((file, i) => i !== index);
        setFiles(newFiles);
        handleRemove(index);
    };
  
    const thumbs = files?.map((file, index) => (
        <div 
            className={classes.thumb}
            key={index}
            onMouseEnter={() => enableDelete(index)}
            onMouseLeave={disableDelete}
        >
            <Fade duration={300} when={deleteEnabled === index}>
                <Tooltip title="Remover imagem">
                    <Button 
                        className={classes.deleteButton} 
                        variant="contained"
                        onClick={() => removeImage(index)}
                        color="primary"
                    >
                        <DeleteIcon />
                    </Button>
                </Tooltip>
            </Fade>
            <div className={classes.thumbInner}>
                <img
                    src={file.preview}
                    className={classes.img}
                    alt="thumb"
                />
            </div>
        </div>
    ));

    useEffect(() => {
        if (files?.length > 0) {
            files.forEach(file => ({...file, preview: `${API_URL}/warehouse/${file?.filename}` }));
            handleAdd(files);
            files.forEach(file => URL.revokeObjectURL(file.preview));
        }
    }, [files]);

    return (
        <Container className={classes.root}>
            <div {...getRootProps({className: classes.dropzone})}>
                <input {...getInputProps()} />
                <Typography variant="body1">
                    Arraste para adicionar ficheiros ou clique aqui
                </Typography>
            </div>
            <aside className={classes.thumbsContainer}>
                <ul>{thumbs}</ul>
            </aside>
        </Container>
    );
}

export default withStyles(styles)(Dropzone);