import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import {
    Tabs,
    Tab,
    Typography,
    Grid,
    TextField,
    IconButton,
    withStyles,
    FormLabel,
    FormHelperText,
    CircularProgress,
    Paper,
    Dialog,
    Button,
    DialogContent,
    DialogActions, FormControlLabel, Checkbox
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NumberFormat from 'react-number-format';
import { Fade } from 'react-reveal';

import Dropzone from '../Dropzone';
import SelectInput from '../SelectInput';
import TextEditor from '../TextEditor';
import DatePicker from '../DatePicker';
import SubmitButton from '../SubmitButton';
import { withContext } from '../../services/context';
import {
    fetchProductVariants,
    postProduct,
    putProduct
} from '../../services/axios';
import styles from './styles';
import NutritionTabs from '../NutritionTabs';
import ImageUpload from '../ImageUpload';

const ProductForm = ({
    classes,
    label,
    onClose,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    context: { productVariants, dispatch },
    isSubmitting,
    status,
    setStatus
}) => {
    const [tab, setTab] = useState(0);
    const [nutritionChanged, setNutritionChanged] = useState(false);

    useEffect(() => {
        const getVariantOptions = async () => {
            const response = await fetchProductVariants();
            dispatch({
                type: "SET_PRODUCT_VARIANTS",
                payload: response.data.content
            });
        }
        getVariantOptions();
    }, [dispatch]);

    const handleTabChange = (e, newTab) => setTab(newTab);

    const handleNutritionChange = (type, data) => {
        setNutritionChanged(true);
        setFieldValue(type, data);
    }

    const handleThumbnailChange = async value => setFieldValue("thumbnail", value);

    const handleAddImages = images => {
        setFieldValue("images", images);
    }

    const handleRemoveImage = index => {
        setFieldValue("images", values.images.filter((image, i) => i !== index));
    }

    return (
        <form onSubmit={handleSubmit} className={classes.form} encType="multipart/form-data">
            <div className={classes.root}>
                <Dialog open={!!status.formResponseCode}>
                    <DialogContent>
                        <Typography variant="h5">
                            { status.formResponseCode === 201 && "Produto guardado com sucesso" }
                            { status.formResponseCode === 200 && "Produto alterado com sucesso" }
                            { status.formResponseCode > 201 && "Erro no servidor. Tente mais tarde" }
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={() => setStatus({ formResponseCode: null })}>Ok</Button>
                    </DialogActions>
                </Dialog>
                <IconButton className={classes.button} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <Paper>
                    <Grid 
                        className={classes.formContainer}
                        container 
                        spacing={4}
                        direction="column"
                        justify="center"
                    >
                        <Typography variant="h5">
                            { label }
                        </Typography>
                        <Tabs 
                            value={tab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            style={{ backgroundColor: "#eee" }}
                        >
                            <Tab label="Informações gerais" />
                            <Tab label="Nutrição"  />
                        </Tabs>
                        { tab === 0 && (
                            <>
                                <Grid item>
                                    <Grid container spacing={4} justify="space-around">
                                        <Grid item className={classes.input}>
                                            <TextField 
                                                name="title_pt"
                                                label="Título em português"
                                                value={values.title_pt}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={!!errors.title_pt && touched.title_pt}
                                                fullWidth
                                            />
                                            { !!errors.title_pt && touched.title_pt && (
                                                <Fade duration="200">
                                                    <FormHelperText error>
                                                        { errors.title_pt }
                                                    </FormHelperText>
                                                </Fade>
                                            )}
                                        </Grid>
                                        <Grid item className={classes.input}>
                                            <TextField 
                                                name="title_en"
                                                label="Título em inglês"
                                                value={values.title_en}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={!!errors.title_en && touched.title_en}
                                                fullWidth
                                            />
                                            { !!errors.title_en && touched.title_en && (
                                                <Fade duration="200">
                                                    <FormHelperText error>
                                                        { errors.title_en }
                                                    </FormHelperText>
                                                </Fade>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={4} justify="space-around" style={{ textAlign: "center" }}>
                                        <Grid item className={classes.input}>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    checked={values.news}
                                                    onChange={handleChange}
                                                    name="news"
                                                    color="primary"
                                                />
                                                }
                                                label="Novidade"
                                            />
                                        </Grid>
                                        <Grid item className={classes.input}>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    checked={values.promoted}
                                                    onChange={handleChange}
                                                    name="promoted"
                                                    color="primary"
                                                />
                                                }
                                                label="Promoção"
                                            />
                                        </Grid>
                                        <Grid item className={classes.input}>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    checked={values.featured}
                                                    onChange={handleChange}
                                                    name="featured"
                                                    color="primary"
                                                />
                                                }
                                                label="Destaque"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container justify="space-around" spacing={4}>
                                        <Grid item style={{ flex: 1 }}>
                                            <Paper className={classes.dropzonePaper}>
                                                <FormLabel>
                                                    Imagens do carrossel
                                                </FormLabel>
                                                <Dropzone 
                                                    values={values.images}
                                                    handleAdd={handleAddImages}
                                                    handleRemove={handleRemoveImage}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item style={{ flex: 1 }}>
                                            <ImageUpload
                                                title="Thumbnail"
                                                buttonText="Selecione um thumbnail"
                                                value={values.thumbnail}
                                                onChange={handleThumbnailChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={4} justify="space-around">
                                        <Grid item className={classes.input}>
                                            <TextField 
                                                name="smallDescription_pt"
                                                label="Descrição em português"
                                                rows={3}
                                                multiline
                                                value={values.smallDescription_pt}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={!!errors.smallDescription_pt && touched.smallDescription_pt}
                                                fullWidth
                                            />
                                            { !!errors.smallDescription_pt && touched.smallDescription_pt && (
                                                <Fade duration="200">
                                                    <FormHelperText error>
                                                        { errors.smallDescription_pt }
                                                    </FormHelperText>
                                                </Fade>
                                            )}
                                        </Grid>
                                        <Grid item className={classes.input}>
                                            <TextField 
                                                name="smallDescription_en"
                                                label="Descrição em inglês"
                                                rows={3}
                                                multiline
                                                value={values.smallDescription_en}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={!!errors.smallDescription_en && touched.smallDescription_en}
                                                fullWidth
                                            />
                                            { !!errors.smallDescription_en && touched.smallDescription_en && (
                                                <Fade duration="200">
                                                    <FormHelperText error>
                                                        { errors.smallDescription_en }
                                                    </FormHelperText>
                                                </Fade>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <TextEditor 
                                        name="bigDescription_pt"
                                        label="Texto em português"
                                        value={values.bigDescription_pt}
                                        onChange={setFieldValue}
                                        error={!!errors.bigDescription_pt && touched.bigDescription_pt}
                                    />
                                    { !!errors.bigDescription_pt && touched.bigDescription_pt && (
                                        <Fade duration="200">
                                            <FormHelperText error>
                                                { errors.bigDescription_pt }
                                            </FormHelperText>
                                        </Fade>
                                    )}
                                </Grid>
                                <Grid item>
                                    <TextEditor 
                                        name="bigDescription_en"
                                        label="Texto em inglês"
                                        value={values.bigDescription_en}
                                        onChange={setFieldValue}
                                        error={!!errors.bigDescription_en && touched.bigDescription_en}
                                    />
                                    { !!errors.bigDescription_en && touched.bigDescription_en && (
                                        <Fade duration="200">
                                            <FormHelperText error>
                                                { errors.bigDescription_en }
                                            </FormHelperText>
                                        </Fade>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={4} justify="space-between">
                                        <Grid item className={classes.input}>
                                            <NumberFormat
                                                name="price"
                                                label="Preço s/IVA (€)"
                                                value={values.price}
                                                error={!!errors.price && touched.price}
                                                decimalSeparator="."
                                                thousandSeparator=""
                                                decimalScale={2}
                                                customInput={TextField}
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                            { !!errors.price && touched.price && (
                                                <Fade duration="200">
                                                    <FormHelperText error>
                                                        { errors.price }
                                                    </FormHelperText>
                                                </Fade>
                                            )}
                                        </Grid>
                                        <Grid item className={classes.input}>
                                            <NumberFormat
                                                name="iva"
                                                label="IVA (%)"
                                                value={values.iva}
                                                error={!!errors.iva && touched.iva}
                                                decimalSeparator="."
                                                thousandSeparator=""
                                                decimalScale={0}
                                                customInput={TextField}
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                            { !!errors.iva && touched.iva && (
                                                <Fade duration="200">
                                                    <FormHelperText error>
                                                        { errors.iva }
                                                    </FormHelperText>
                                                </Fade>
                                            )}
                                        </Grid>
                                        <Grid item className={classes.input}>
                                            <NumberFormat
                                                name="discount"
                                                label="Desconto (%)"
                                                value={values.discount}
                                                error={!!errors.discount && touched.discount}
                                                decimalSeparator="."
                                                thousandSeparator=""
                                                decimalScale={0}
                                                customInput={TextField}
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                            { !!errors.discount && touched.discount && (
                                                <Fade duration="200">
                                                    <FormHelperText error>
                                                        { errors.discount }
                                                    </FormHelperText>
                                                </Fade>
                                            )}
                                        </Grid>
                                        <Grid item className={classes.input}>
                                            {productVariants ? (
                                                <SelectInput
                                                    values={values.variants}
                                                    label="Categorias"
                                                    options={productVariants.sort((a, b) => {
                                                        if(a[`title_pt`] < b[`title_pt`]) { return -1; }
                                                        if(a[`title_pt`] > b[`title_pt`]) { return 1; }
                                                        return 0;
                                                    })}
                                                    onChange={variants => setFieldValue("variants", variants)}
                                                    labelExtractor="category"
                                                    idExtractor="category"
                                                    multiple
                                                    error={!!errors.variants && touched.variants}
                                                />
                                            ) : (
                                                <CircularProgress size={24} />
                                            )}
                                            { !!errors.variants && touched.variants && (
                                                <Fade duration="200">
                                                    <FormHelperText error>
                                                        { errors.variants }
                                                    </FormHelperText>
                                                </Fade>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={4} justify="space-around">
                                        <Grid item className={classes.input}>
                                            <TextField 
                                                name="moreInfo_pt"
                                                label="Mais informações em português"
                                                rows={3}
                                                multiline
                                                value={values.moreInfo_pt}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={!!errors.moreInfo_pt && touched.moreInfo_pt}
                                                fullWidth
                                            />
                                            { !!errors.moreInfo_pt && touched.moreInfo_pt && (
                                                <Fade duration="200">
                                                    <FormHelperText error>
                                                        { errors.moreInfo_pt }
                                                    </FormHelperText>
                                                </Fade>
                                            )}
                                        </Grid>
                                        <Grid item className={classes.input}>
                                            <TextField 
                                                name="moreInfo_en"
                                                label="Mais informações em inglês"
                                                rows={3}
                                                multiline
                                                value={values.moreInfo_en}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={!!errors.moreInfo_en && touched.moreInfo_en}
                                                fullWidth
                                            />
                                            { !!errors.moreInfo_en && touched.moreInfo_en && (
                                                <Fade duration="200">
                                                    <FormHelperText error>
                                                        { errors.moreInfo_en }
                                                    </FormHelperText>
                                                </Fade>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={4} justify="space-around">
                                        <Grid item className={classes.input}>
                                            <TextEditor 
                                                name="useMode_pt"
                                                label="Modo de utilização em português"
                                                value={values.useMode_pt}
                                                onChange={setFieldValue}
                                                error={!!errors.useMode_pt && touched.useMode_pt}
                                            />
                                            { !!errors.useMode_pt && touched.useMode_pt && (
                                                <Fade duration="200">
                                                    <FormHelperText error>
                                                        { errors.useMode_pt }
                                                    </FormHelperText>
                                                </Fade>
                                            )}
                                        </Grid>
                                        <Grid item className={classes.input}>
                                            <TextEditor
                                                name="useMode_en"
                                                label="Modo de utilização em inglês"
                                                value={values.useMode_en}
                                                onChange={setFieldValue}
                                                error={!!errors.useMode_en && touched.useMode_en}
                                            />
                                            { !!errors.useMode_en && touched.useMode_en && (
                                                <Fade duration="200">
                                                    <FormHelperText error>
                                                        { errors.useMode_en }
                                                    </FormHelperText>
                                                </Fade>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={4} justify="space-around">
                                        <Grid item className={classes.input}>
                                            <TextEditor
                                                name="faq_pt"
                                                label="FAQ em português"
                                                value={values.faq_pt}
                                                onChange={setFieldValue}
                                                error={!!errors.faq_pt && touched.faq_pt}
                                            />
                                            { !!errors.faq_pt && touched.faq_pt && (
                                                <Fade duration="200">
                                                    <FormHelperText error>
                                                        { errors.faq_pt }
                                                    </FormHelperText>
                                                </Fade>
                                            )}
                                        </Grid>
                                        <Grid item className={classes.input}>
                                            <TextEditor
                                                name="faq_en"
                                                label="FAQ em inglês"
                                                value={values.faq_en}
                                                onChange={setFieldValue}
                                                error={!!errors.faq_en && touched.faq_en}
                                            />
                                            { !!errors.faq_en && touched.faq_en && (
                                                <Fade duration="200">
                                                    <FormHelperText error>
                                                        { errors.faq_en }
                                                    </FormHelperText>
                                                </Fade>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        { tab === 1 && (
                            <Grid item>
                                <Grid 
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Grid container spacing={4}>
                                            <Grid item className={classes.input}>
                                                <TextField 
                                                    name="stock"
                                                    label="Stock"
                                                    value={values.stock}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={!!errors.stock && touched.stock}
                                                    fullWidth
                                                />
                                                { !!errors.stock && touched.stock && (
                                                    <Fade duration="200">
                                                        <FormHelperText error>
                                                            { errors.stock }
                                                        </FormHelperText>
                                                    </Fade>
                                                )} 
                                            </Grid>
                                            <Grid item className={classes.input}>
                                                <TextField 
                                                    name="lot"
                                                    label="Lote"
                                                    value={values.lot}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={!!errors.lot && touched.lot}
                                                    fullWidth
                                                />
                                                { !!errors.lot && touched.lot && (
                                                    <Fade duration="200">
                                                        <FormHelperText error>
                                                            { errors.lot }
                                                        </FormHelperText>
                                                    </Fade>
                                                )} 
                                            </Grid>
                                            <Grid item className={classes.input}>
                                                <DatePicker 
                                                    label="Expiração"
                                                    value={values.expiration}
                                                    onChange={
                                                        date => setFieldValue("expiration", date)
                                                    }
                                                    onBlur={handleBlur}
                                                />
                                                { !!errors.expiration && touched.expiration && (
                                                    <Fade duration="200">
                                                        <FormHelperText error>
                                                            { errors.expiration }
                                                        </FormHelperText>
                                                    </Fade>
                                                )} 
                                            </Grid>
                                            <Grid item className={classes.input}>
                                                <TextField 
                                                    name="dosage"
                                                    label="Dosagem"
                                                    value={values.dosage}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={!!errors.dosage && touched.dosage}
                                                    fullWidth
                                                />
                                                { !!errors.dosage && touched.dosage && (
                                                    <Fade duration="200">
                                                        <FormHelperText error>
                                                            { errors.dosage }
                                                        </FormHelperText>
                                                    </Fade>
                                                )} 
                                            </Grid>
                                            <Grid item className={classes.input}>
                                                <TextField 
                                                    name="totalDosage"
                                                    label="Dosagem total"
                                                    value={values.totalDosage}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={!!errors.totalDosage && touched.totalDosage}
                                                    fullWidth
                                                />
                                                { !!errors.totalDosage && touched.totalDosage && (
                                                    <Fade duration="200">
                                                        <FormHelperText error>
                                                            { errors.totalDosage }
                                                        </FormHelperText>
                                                    </Fade>
                                                )} 
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{ marginTop: 50 }}>
                                        <NutritionTabs 
                                            values={{
                                                nutrition100: values.nutrition100,
                                                nutritionDose: values.nutritionDose,
                                                nutritionDay: values.nutritionDay
                                            }} 
                                            handleNutritionChange={handleNutritionChange}
                                        />
                                        { nutritionChanged && (
                                            <FormHelperText>
                                                Nutrição guardada
                                            </FormHelperText>
                                        )}
                                        { !!errors.nutrition && touched.nutrition && (
                                            <Fade duration="200">
                                                <FormHelperText error>
                                                    { errors.nutrition_en }
                                                </FormHelperText>
                                            </Fade>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item>
                            <Grid container justify="center" alignItems="center" direction="column">
                                <Grid item>
                                    <SubmitButton variant="contained" color="primary" isSubmitting={isSubmitting}>
                                        Submeter
                                    </SubmitButton>
                                </Grid>
                                <Grid item>
                                    {Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0 && (
                                        <FormHelperText error>
                                            Existem erros no formulário
                                        </FormHelperText>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </form>
    )
}

export default compose(
    withContext,
    withStyles(styles),
    withFormik({
        mapPropsToStatus: () => ({ formResponseCode: null }),
        mapPropsToValues: ({ values }) => {
            const currentDate = new Date('01/01/2025');
            if (values) {
                return values
            }
            return {
                title_pt: "",
                title_en: "",
                thumbnail: null,
                images: [],
                smallDescription_pt: "",
                smallDescription_en: "",
                bigDescription_pt: "",
                bigDescription_en: "",
                price: "",
                iva: 0,
                discount: "",
                variants: [],
                moreInfo_pt: "",
                moreInfo_en: "",
                useMode_pt: "",
                useMode_en: "",
                nutrition100: null,
                nutritionDay: null,
                nutritionDose: null,
                stock: 0,
                lot: "",
                expiration: currentDate.toISOString(),
                dosage: "",
                totalDosage: "",
                news: false,
                promoted: false,
                featured: false
            }
        },
        handleSubmit: async (values, { props: { context: { dispatch } }, setSubmitting, setStatus }) => {
            let response;
            let productValues = values;

            if (values._id) {
                productValues = {
                    ...values,
                    iva: parseInt(values.iva),
                    stock: parseInt(values.stock),
                    variants: values.variants
                }
                response = await putProduct(productValues);
                dispatch({
                    type: "UPDATE_PRODUCT",
                    payload: response.data.content
                });
            } else {
                productValues = {
                    ...values,
                    iva: parseInt(values.iva),
                    stock: parseInt(values.stock),
                    variants: values.variants.map(variant => variant.category)
                }
                response = await postProduct(productValues);
                dispatch({
                    type: "ADD_PRODUCT",
                    payload: response.data.content
                })
            }

            setStatus({
                formResponseCode: response.data.status
            })

            setSubmitting(false);
        },
        validate: values => {
            const errors = {};
            Object.entries(values).forEach(value => {
                if(value[0] ===  "variants" && value[1].length === 0) {
                    errors[value[0]] = "Este campo não pode estar vazio"
                } else if((value[0] !== "nutrition100" && value[0] !== "nutritionDose" && value[0] !== "nutritionDay") && values[1] === "") {
                    errors[value[0]] = "Campo obrigatório"  
                }
            })
            return errors;
        }
    })
)(ProductForm);