import React, { useState } from 'react';
import {
    Tabs,
    Tab,
    Paper,
    FormLabel
} from '@material-ui/core';

import NutritionForm from '../NutritionForm';
import { Fade } from 'react-reveal';

const NutritionTabs = ({
    values: { 
        nutrition100,
        nutritionDose,
        nutritionDay
    }, 
    handleNutritionChange 
}) => {
    const [index, setIndex] = useState(0);

    const handleChange = (event, index) => setIndex(index);

    const handleNutrition100Change = data => handleNutritionChange("nutrition100", data);

    const handleNutritionDoseChange = data => handleNutritionChange("nutritionDose", data);

    const handleNutritionDayChange = data => handleNutritionChange("nutritionDay", data);

    return (
        <Paper>
            <FormLabel>
                Nutrição
            </FormLabel>
            <Tabs 
                value={index}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab label="Por 100g" />
                <Tab label="Por dose" />
                <Tab label="Por dia" />
            </Tabs>
            { index === 0 && (
                <Fade>
                    <NutritionForm 
                        value={nutrition100} 
                        handleNutritionChange={handleNutrition100Change}
                    />
                </Fade>
            )}
            { index === 1 && (
                <Fade>
                    <NutritionForm 
                        value={nutritionDose} 
                        handleNutritionChange={handleNutritionDoseChange}
                    />
                </Fade>
            )}
            { index === 2 && (
                <Fade>
                    <NutritionForm 
                        value={nutritionDay} 
                        handleNutritionChange={handleNutritionDayChange}
                    />
                </Fade>
            )}
        </Paper>
    )
}

export default NutritionTabs;
