import React, { useEffect } from 'react';
import { compose } from 'recompose';
import {
    Grid,
    CircularProgress
} from '@material-ui/core';

import { withContext } from '../../services/context';
import { fetchSEO } from '../../services/axios';
import SEOForm from '../../components/SEOForm';

const SEO = ({ context: { seo, dispatch } }) => {
    useEffect(() => {
        const getSEO = async () => {
            const response = await fetchSEO();
            dispatch({
                type: 'SET_SEO',
                payload: response.data.content
            });
        }
        getSEO();
    }, [dispatch]);

    if (!seo) {
        return (
            <Grid container justify="center" alignItems="center">
                <CircularProgress size={60}/>
            </Grid>
        )
    }
    
    return (
        <SEOForm values={seo} />
    )
}

export default compose(
    withContext
)(SEO);