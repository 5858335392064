export default () => ({
    root: {
        padding: 30,
        flex: 1
    },
    thumbnail: {
        marginBottom: 20
    },
    thumbnailButton: {
        marginTop: 20,
        marginBottom: 20
    }
})