export default () => ({
    root: {
        width: "70%",
        margin: "auto"
    },
    button: {
        position: "fixed",
        right: 0,
        top: 0
    }
})