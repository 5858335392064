import React from 'react';
import {
    Snackbar
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SessionExpired = ({ open, onClose }) => {
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
            <Alert severity="error">A sua sessão expirou</Alert>
        </Snackbar>
    )
}

export default SessionExpired;