import React, { useEffect } from 'react';
import { compose } from 'recompose';
import {
    Grid,
    CircularProgress,
    withStyles
} from '@material-ui/core';

import styles from './styles';
import Table from '../../components/Table';
import { fetchNewsletter } from '../../services/axios';
import { withContext } from '../../services/context';

const Newsletter = ({ classes, context: { newsletter, dispatch } }) => {

    useEffect(() => {
        const getNewsletter = async () => {
            const response = await fetchNewsletter();
            dispatch({
                type: "SET_NEWSLETTER",
                payload: response.data.content
            });
        }
        getNewsletter();
    }, [dispatch]);

    const columns = [
        { title: 'ID', field: '_id', hidden: true },
        { title: 'Email', field: 'email' },
        { title: 'Criado', field: 'createdAt' }
    ]

    if (!newsletter) {
        return (
            <Grid container justify="center" alignItems="center">
                <CircularProgress size={60}/>
            </Grid>
        );
    }

    return (
        <div className={classes.root}>
            <Grid container direction="column" spacing={4}>
                <Grid item style={{ width: "50%", margin: "auto" }}>
                    <Table title="Emails" columns={columns} data={newsletter} />
                </Grid>
            </Grid>
        </div>
    );
}

export default compose(
    withContext,
    withStyles(styles)
)(Newsletter);