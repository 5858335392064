export default () => ({
    root: {
        marginLeft: "250px"
    },
    checkCircleIcon: {
        color: "green"
    },
    cancelIcon: {
        color: "red"
    },
    menuIcon: {
        marginRight: "20px"
    }
})