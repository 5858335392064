import React from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import {
    Typography,
    Grid,
    TextField,
    FormHelperText,
    IconButton,
    withStyles,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { withContext } from '../../services/context';
import { postHelp, putHelp } from '../../services/axios';
import styles from './styles';
import TextEditor from '../TextEditor';
import SubmitButton from '../SubmitButton';

const HelpForm = ({
    classes,
    onClose,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    status,
    setStatus
}) => {
    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Dialog open={!!status.formResponseCode}>
                <DialogContent>
                    <Typography variant="h5">
                        { status.formResponseCode === 201 && "Categoria guardada com sucesso" }
                        { status.formResponseCode === 200 && "Categoria alterada com sucesso" }
                        { status.formResponseCode > 201 && "Erro no servidor. Tente mais tarde" }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => setStatus({ formResponseCode: null })}>Ok</Button>
                </DialogActions>
            </Dialog>
            <IconButton className={classes.button} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <Grid container direction="column" justify="center" spacing={4}>
                <Typography variant="h5">
                    Adicionar ajuda
                </Typography>
                <Grid item>
                    <Grid container spacing={4}>
                        <Grid item className={classes.input}>
                            <TextField 
                                name="title_pt"
                                label="Título em português"
                                value={values.title_pt}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                            />
                            {errors.title_pt && touched.title_pt && (
                                <FormHelperText error>
                                    { errors.title_pt }
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item className={classes.input}>
                            <TextField 
                                name="title_en"
                                label="Título em inglês"
                                value={values.title_en}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                            />
                            {errors.title_en && touched.title_en && (
                                <FormHelperText error>
                                    { errors.title_en }
                                </FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <TextEditor 
                        name="text_pt"
                        label="Texto em português"
                        value={values.text_pt}
                        onChange={setFieldValue}
                    />
                </Grid>
                <Grid item>
                    <TextEditor 
                        name="text_en"
                        label="Texto em inglês"
                        value={values.text_en}
                        onChange={setFieldValue}
                    />
                </Grid>
                <Grid item>
                    <Grid container justify="center">
                        <SubmitButton variant="contained" color="primary" isSubmitting={isSubmitting}>
                            Submeter
                        </SubmitButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default compose(
    withContext,
    withStyles(styles),
    withFormik({
        mapPropsToStatus: () => ({ formResponseCode: null }),
        mapPropsToValues: ({ values }) => {
            if (values) {
                return values;
            }
            return {
                title_pt: "",
                title_en: "",
                text_pt: "",
                text_en: ""
            }
        },
        handleSubmit: async (values, { props: { context: { dispatch }}, setSubmitting, setStatus }) => {
            let response;
            if (values._id) {
                response = await putHelp(values);
                dispatch({
                    type: "UPDATE_HELPS",
                    payload: response.data.content
                })
            } else {
                response = await postHelp(values);
                dispatch({
                    type: "ADD_HELPS",
                    payload: response.data.content
                })
            }
            setStatus({ formResponseCode: response.data.status });
            setSubmitting(false);
        }
    })
)(HelpForm);