import React from 'react';
import { Grid } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import PropTypes from 'prop-types';

const DatePicker = ({ label, value, onChange, onBlur }) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} local={ptBR}>
            <Grid container justify="space-around">
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    label={label}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
}

DatePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
}

export default DatePicker;