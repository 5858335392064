export default () => ({
    form: {
        padding: 30
    },
    input: {
        flex: 1
    },
    button: {
        position: "fixed",
        right: 0,
        top: 0
    }
})