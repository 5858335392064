import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import {
    Button,
    Grid,
    withStyles,
    CircularProgress,
    Dialog
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Fade } from 'react-reveal';

import { withContext } from '../../services/context';
import { fetchProducts, updatePublishProduct, removeProduct, API_URL } from '../../services/axios';
import Table from '../../components/Table';
import RowActions from '../../components/Table/RowActions';
import ProductForm from '../../components/ProductForm';
import ConfirmationModal from '../../components/ConfirmationModal';
import EditDiscountModal from '../../components/EditDiscountModal';
import styles from './styles';
import EditStatusModal from '../../components/EditStatusModal';

const Products = ({ classes, context: { products, dispatch } }) => {
    const [formOpen, setFormOpen] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [discountOpen, setDiscountOpen] = useState(false);
    const [statusesOpen, setStatusesOpen] = useState(false);
    const [confirmationProps, setConfirmationProps] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleStatusesOpen = () => setStatusesOpen(true);

    const handleStatusesClose = () => setStatusesOpen(false);

    const handleDiscountOpen = () => setDiscountOpen(true);

    const handleDiscountClose = () => {
        setSelectedProduct(null);
        setDiscountOpen(false)
    };

    const handleFormOpen = () => setFormOpen(true);

    const handleFormClose = () => {
        setSelectedProduct(null);
        setFormOpen(false);
    };

    const handleConfirmationOpen = () => setConfirmationOpen(true);

    const handleConfirmationClose = () => {
        setConfirmationOpen(false)
        setConfirmationProps(null);
    };

    const editProduct = product => {
        setSelectedProduct(product);
        handleFormOpen();
    }

    const publishProduct = product => {
        setConfirmationProps({
            title: "Publicar produto",
            text: `Deseja publicar o produto ${product.title_pt}?`,
            action: async () => {
                const response = await updatePublishProduct(product._id, !product.published);
                dispatch({
                    type: "UPDATE_PRODUCT",
                    payload: response.data.content
                })
            }
        })
        handleConfirmationOpen();
    }

    const deleteProduct = product => {
        setConfirmationProps({
            title: "Apagar produto",
            text: `Deseja apagar o produto ${product.title_pt}?`,
            action: async () => {
                const response = await removeProduct(product._id);
                dispatch({
                    type: "DELETE_PRODUCT",
                    payload: response.data.content
                })
            }
        });
        handleConfirmationOpen();
    }
    
    const editDiscount = product => {
        setSelectedProduct(product);
        handleDiscountOpen();
    }

    const editStatuses = product => {
        setSelectedProduct(product);
        handleStatusesOpen();
    }

    const actions = {
        edit: editProduct,
        publish: publishProduct,
        delete: deleteProduct,
        editDiscount,
        editStatuses
    }

    const columns = [
        { title: 'Ações', render: row => (
            <RowActions target={row} actions={actions} />
        )},
        { title: 'ID', field: '_id', hidden: true },
        { title: 'Thumbnail', field: 'thumbnail.filename', render: row => 
            <img
                className={classes.thumb} 
                src={`${API_URL}/warehouse/${row.thumbnail.filename}`} 
                alt="thumbnail"
            />
        },
        { title: 'Título', field: 'title_pt', searchable: true },
        { title: 'Preço', field: 'price' },
        { title: 'Desconto', field: 'discount' },
        { title: 'Stock', field: 'stock' },
        { title: 'Vizualizações', field: 'views' },
        { title: 'Aquisições', field: 'bought' },
        { title: 'Publicado', field: 'published', render: row =>
            row.published ? <CheckIcon /> : <CloseIcon />
        }
    ]

    useEffect(() => {
        const getProducts = async () => {
            const response = await fetchProducts();
            dispatch({
                type: "SET_PRODUCTS",
                payload: response.data.content
            })
        };
        getProducts();
    }, [dispatch]);

    if (!products) {
        return (
            <Grid container justify="center" alignItems="center">
                <CircularProgress size={60}/>
            </Grid>
        )
    }

    return (
        <Fade duration={400}>
            <ConfirmationModal 
                open={confirmationOpen}
                handleClose={handleConfirmationClose}
                {...confirmationProps}
            />
            <Dialog
                open={formOpen} 
                onClose={handleFormClose}
                fullScreen
                maxWidth="lg"
            >
                <ProductForm
                    label={selectedProduct ? `Editar ${selectedProduct.title_pt}` : "Adicionar produto"}
                    values={selectedProduct}
                    onClose={handleFormClose} 
                />
            </Dialog>
            <Dialog open={discountOpen} onClose={handleDiscountClose}>
                <EditDiscountModal product={selectedProduct} />
            </Dialog>
            <Dialog open={statusesOpen} onClose={handleStatusesClose} fullWidth>
                <EditStatusModal product={selectedProduct} />
            </Dialog>
            <div className={classes.root}>
                <Grid container direction="column" spacing={4}>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleFormOpen}>
                            Adicionar produto
                        </Button>
                    </Grid>
                    <Grid item style={{ flex: 1 }}>
                        <Table title="Produtos" columns={columns} data={products} />
                    </Grid>
                </Grid>
            </div>
        </Fade>
    )
}

export default compose(
    withContext,
    withStyles(styles),
)(Products);