import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import {
    Grid,
    withStyles,
    Button,
    Dialog,
    CircularProgress
} from '@material-ui/core'
import { Fade } from 'react-reveal';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles';
import { withContext } from '../../services/context';
import { fetchPages, updatePublishPage, removePage } from '../../services/axios';
import Table from '../../components/Table';
import RowActions from '../../components/Table/RowActions';
import PageForm from '../../components/PageForm';
import ConfirmationModal from '../../components/ConfirmationModal';

const PageList = ({ classes, context: { pages, dispatch } }) => {
    const [pageSelected, setPageSelected] = useState(null);
    const [formOpen, setFormOpen] = useState(false);
    const [confirmationProps, setConfirmationProps] = useState(null);
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const handleFormOpen = () => setFormOpen(true);

    const handleFormClose = () => setFormOpen(false);

    const handleConfirmationOpen = () => setConfirmationOpen(true);

    const handleConfirmationClose = () => setConfirmationOpen(false);

    const editPage = page => {
        setPageSelected(page);
        handleFormOpen();
    }

    const publishPage = page => {
        setConfirmationProps({
            title: "Publicar página",
            text: `Deseja publicar a página ${page.title_pt}?`,
            action: async () => {
                const response = await updatePublishPage(page._id, { 
                    title_pt: page.title_pt,
                    title_en: page.title_en,
                    text_pt: page.text_pt,
                    text_en: page.text_en,
                    type: page.type,
                    image: page.image._id, 
                    published: !page.published 
                });
                dispatch({
                    type: "UPDATE_PAGE",
                    payload: response.data.content
                })
            }
        })
        handleConfirmationOpen();
    }

    const deletePage = page => {
        setConfirmationProps({
            title: "Apagar página",
            text: `Deseja apagar a página ${page.title_pt}?`,
            action: async () => {
                const response = await removePage(page._id);
                dispatch({
                    type: "DELETE_PAGE",
                    payload: response.data.content
                })
            }
        })
        handleConfirmationOpen();
    }

    const actions = {
        edit: editPage,
        publish: publishPage,
        delete: deletePage
    }

    const columns = [
        { title: 'Ações', render: row => (
            <RowActions target={row} actions={actions} />
        )},
        { title: 'ID', field: '_id', hidden: true },
        { title: 'Tipo', field: 'type' },        
        { title: 'Publicado', field: 'published', render: row =>
            row.published ? <CheckIcon /> : <CloseIcon />
        }        
    ];

    useEffect(() => {
        const getPages = async () => {
            const response = await fetchPages();
            dispatch({
                type: 'SET_PAGES',
                payload: response.data.content
            });
        }
        getPages();
    }, [dispatch])

    if (!pages) {
        return (
            <Grid container justify="center" alignItems="center">
                <CircularProgress size={60}/>
            </Grid>
        )
    }

    return (
        <Fade duration={400}>
            <ConfirmationModal
                open={confirmationOpen}
                handleClose={handleConfirmationClose}
                {...confirmationProps}
            />
            <Dialog open={formOpen} onClose={handleFormClose} fullScreen>
                <PageForm 
                    label={pageSelected ? `Editar ${pageSelected.title_pt}` : "Adicionar página"}
                    values={pageSelected}
                    onClose={handleFormClose} 
                />
            </Dialog>
            <div className={classes.root}>
                <Grid container direction="column" spacing={4}>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleFormOpen}>
                            Adicionar Página
                        </Button>
                    </Grid>
                    <Grid item style={{ flex: 1 }}>
                        <Table title="Conteúdo" columns={columns} data={pages} />
                    </Grid>
                </Grid>
            </div>
        </Fade>
    );
}

export default compose(
    withContext,
    withStyles(styles)
)(PageList);