import React from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import {
    Grid,
    TextField,
    Paper,
    Typography,
    withStyles,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';

import { withContext } from '../../services/context';
import { putContactInfo } from '../../services/axios';
import styles from './styles';
import SubmitButton from '../SubmitButton';

const ContactInfoForm = ({
    classes,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    status,
    setStatus
}) => {    
    return (
        <form className={classes.root} onSubmit={handleSubmit}>
            <Dialog open={!!status.formResponseCode}>
                <DialogContent>
                    <Typography variant="h5">
                        { status.formResponseCode === 201 && "Contactos guardados com sucesso" }
                        { status.formResponseCode === 200 && "Contactos alterados com sucesso" }
                        { status.formResponseCode > 201 && "Erro no servidor. Tente mais tarde" }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => setStatus({ formResponseCode: null })}>Ok</Button>
                </DialogActions>
            </Dialog>
            <Paper className={classes.paper}>
                <Typography variant="h5" className={classes.formTitle}>
                    Configurações do site
                </Typography>
                <Grid container spacing={4} justify="center">
                    <Grid item style={{ flex: 1 }}>
                        <Typography variant="h5" style={{ marginBottom: "20px" }}>Português</Typography>
                        <Grid container direction="column" spacing={4}>
                            <Grid item className={classes.inputContainer}>
                                <TextField
                                    name="title_pt"
                                    label="Título"
                                    value={values.title_pt}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!errors.title_pt && touched.title_pt}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item className={classes.inputContainer}>
                                <TextField 
                                    name="description_pt"
                                    label="Descrição"
                                    value={values.description_pt}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!errors.description_pt && touched.description_pt}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ flex: 1 }}>
                        <Typography variant="h5" style={{ marginBottom: "20px" }}>Inglês</Typography>
                        <Grid container direction="column" spacing={4}>
                            <Grid item className={classes.inputContainer}>
                                <TextField
                                    name="title_en"
                                    label="Título"
                                    value={values.title_en}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!errors.title_en && touched.title_en}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item className={classes.inputContainer}>
                                <TextField 
                                    name="description_en"
                                    label="Descrição"
                                    value={values.description_en}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!errors.description_en && touched.description_en}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={4} direction="column">
                    <Grid item style={{ flex: 1 }}>
                        <Grid container direction="column" spacing={4}>
                            <Grid item className={classes.inputContainer}>
                                <TextField
                                    name="address"
                                    label="Morada"
                                    value={values.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!errors.address && touched.address}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={4}>
                            <Grid item className={classes.inputContainer}>
                                <TextField 
                                    name="email"
                                    label="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!errors.email && touched.email}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item className={classes.inputContainer}>
                                <TextField 
                                    name="telephone"
                                    label="Email"
                                    value={values.telephone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!errors.telephone && touched.telephone}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{ marginTop: "20px" }}>
                    <Grid container justify="center">
                        <Grid item>
                            <SubmitButton 
                                variant="contained"
                                color="primary"
                                isSubmitting={isSubmitting}
                            >
                                Guardar
                            </SubmitButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </form>
    );
}

export default compose(
    withContext,
    withStyles(styles),
    withFormik({
        mapPropsToStatus: () => ({ formResponseCode: null }),
        mapPropsToValues: ({ contactInfo }) => {
            return {
                _id: contactInfo._id || "",
                title_pt: contactInfo?.title_pt || "",
                title_en: contactInfo?.title_en || "",
                description_pt: contactInfo?.description_pt || "",
                description_en: contactInfo?.description_en || "",
                address: contactInfo?.address || "",
                telephone: contactInfo?.telephone || "",
                email: contactInfo?.email || "",
            }
        },
        handleSubmit: async (values, { props: { context: { dispatch } }, setSubmitting, setStatus }) => {
            const response = await putContactInfo(values);
            dispatch({
                type: "SET_CONTACT_INFO",
                payload: response.data.content
            });
            setSubmitting(false);
            setStatus({ formResponseCode: response.data.status });
        }
    })
)(ContactInfoForm);