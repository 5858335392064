import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { 
    Button,
    CircularProgress,
    Dialog,
    Grid,
    withStyles
} from '@material-ui/core';
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import styles from './styles';
import Table from '../../components/Table';
import { withContext } from '../../services/context';
import { API_URL, fetchBanners, removeBanner, updatePublishBanner } from '../../services/axios';
import RowActions from '../../components/Table/RowActions';
import { Fade } from 'react-reveal';
import ConfirmationModal from '../../components/ConfirmationModal';
import BannerForm from '../../components/BannerForm';

const BannersScreen = ({ 
    classes,
    context: {
        banners,
        dispatch
    } 
}) => {
    const [formOpen, setFormOpen] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationProps, setConfirmationProps] = useState(null);
    const [selectedBanner, setSelectedBanner] = useState(null);

    const handleFormOpen = () => setFormOpen(true);

    const handleFormClose = () => {
        setSelectedBanner(null);
        setFormOpen(false);
    };

    const handleConfirmationOpen = () => setConfirmationOpen(true);

    const handleConfirmationClose = () => {
        setConfirmationOpen(false)
        setConfirmationProps(null);
    };

    useEffect(() => {
        const getBanners = async () => {
            const response = await fetchBanners();
            dispatch({
                type: "SET_BANNERS",
                payload: response.data.content
            });
        }
        getBanners();
    }, [dispatch]);

    if (!banners) {
        return (
            <Grid container justify="center" alignItems="center">
                <CircularProgress size={60}/>
            </Grid>
        )
    }

    const editBanner = banner => {
        setSelectedBanner(banner);
        handleFormOpen();
    }

    const publishBanner = banner => {
        setConfirmationProps({
            title: "Publicar banner",
            text: `Deseja publicar o banner ${banner.title_pt}?`,
            action: async () => {
                const response = await updatePublishBanner(banner._id, !banner.published);
                dispatch({
                    type: "UPDATE_BANNER",
                    payload: response.data.content
                })
            }
        })
        handleConfirmationOpen();
    }

    const deleteBanner = banner => {
        setConfirmationProps({
            title: "Apagar produto",
            text: `Deseja apagar o banner ${banner.title_pt}?`,
            action: async () => {
                const response = await removeBanner(banner._id);
                dispatch({
                    type: "DELETE_BANNER",
                    payload: response.data.content
                })
            }
        });
        handleConfirmationOpen();
    }

    const actions = {
        edit: editBanner,
        publish: publishBanner,
        delete: deleteBanner
    }

    const columns = [
        { title: 'Ações', render: row => (
            <RowActions target={row} actions={actions} />
        )},
        { title: 'ID', field: '_id', hidden: true },
        { title: 'Imagem', field: 'image.filename', render: row => 
            <img
                className={classes.thumb} 
                src={`${API_URL}/warehouse/${row.image.filename}`} 
                alt="thumbnail"
            />
        },
        { title: 'Grupo', field: 'placement', searchable: true },
        { title: 'Tipo', field: 'type', searchable: true },
        { title: 'Título', field: 'title_pt', searchable: true },
        { title: 'Subtítulo', field: 'subtitle_pt' },
        { title: 'Publicado', field: 'published', render: row =>
            row.published ? <CheckIcon /> : <CloseIcon />
        }
    ];

    return (
        <Fade duration={400}>
            <ConfirmationModal
                open={confirmationOpen}
                handleClose={handleConfirmationClose}
                {...confirmationProps}
            />
            <Dialog
                open={formOpen} 
                onClose={handleFormClose}
                fullScreen
                maxWidth="lg"
            >
                <BannerForm
                    label={selectedBanner ? `Editar ${selectedBanner.title_pt}` : "Adicionar banner"}
                    values={selectedBanner}
                    onClose={handleFormClose} 
                />
            </Dialog>
            <div className={classes.root}>
                <Grid container direction="column" spacing={4}>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleFormOpen}>
                            Adicionar banner
                        </Button>
                    </Grid>
                    <Grid item style={{ flex: 1 }}>
                        <Table title="Banners" columns={columns} data={banners} />
                    </Grid>
                </Grid>
            </div>
        </Fade>
    );
}

export default compose(
    withContext,
    withStyles(styles)
)(BannersScreen);