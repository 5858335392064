import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import {
    Grid,
    Button,
    CircularProgress,
    Tooltip,
    Dialog,
    Stepper,
    Step,
    StepLabel,
    withStyles
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import CheckIcon from '@material-ui/icons/Check';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Fade } from 'react-reveal';
import moment from 'moment'

import { withContext } from '../../services/context';
import styles from './styles';
import { fetchOrders, removeOrder, changeOrderStatus } from '../../services/axios';
import Table from '../../components/Table';
import RowActions from '../../components/Table/RowActions';
import OrderForm from '../../components/OrderForm';
import ConfirmationModal from '../../components/ConfirmationModal';
import OrderDetails from '../../components/OrderDetails';

const Orders = ({ 
    classes, 
    context: { 
        orders,
        dispatch
    } 
}) => {
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationProps, setConfirmationProps] = useState(null);
    const [orderSelected, setOrderSelected] = useState(null);

    const handleDetailsOpen = () => setDetailsOpen(true);

    const handleDetailsClose = () => setDetailsOpen(false)

    const handleFormOpen = () => setFormOpen(true);

    const handleFormClose = () => {
        setOrderSelected(null);
        setFormOpen(false)
    };

    const handleConfirmationOpen = () => setConfirmationOpen(true);

    const handleConfirmationClose = () => setConfirmationOpen(false);

    const viewDetails = order => {
        setOrderSelected(order);
        handleDetailsOpen();
    }

    const editOrder = order => {
        setOrderSelected(order);
        handleFormOpen();
    }

    const deleteOrder = order => {
        setConfirmationProps({
            title: "Apagar encomenda",
            text: `Deseja apagar a encomenda de ${order.user?.email}?`,
            action: async () => {
                const response = await removeOrder(order._id);
                dispatch({
                    type: "DELETE_ORDER",
                    payload: response.data.content
                })
            }
        });
        handleConfirmationOpen();
    }

    const updateStatus = async (order, status) => {
        setConfirmationProps({
            title: "Actualizar estado da encomenda",
            text: `Deseja actualizar o estado da encomenda de ${order.user?.email} para "${status}"?`,
            action: async () => {
                const response = await changeOrderStatus(order._id, status);
                dispatch({
                    type: "UPDATE_ORDER",
                    payload: response.data.content
                })
            }
        });
        handleConfirmationOpen();
    }

    const actions = {
        view: viewDetails,
        changeStatus: [
            { 
                label: <><InputIcon className={classes.menuIcon} />Recebido</>, 
                do: async order => await updateStatus(order, 'received') 
            },
            { 
                label: <><CheckIcon className={classes.menuIcon} />Processado</>, 
                do: async order => await updateStatus(order, 'processed') 
            },
            { 
                label: <><LocalShippingIcon className={classes.menuIcon} />Em trânsito</>, 
                do: async order => await updateStatus(order, 'in transit') 
            },
            { 
                label: <><DoneAllIcon className={classes.menuIcon} />Entregue</>, 
                do: async order => await updateStatus(order, 'delivered') 
            },
            { 
                label: <><CancelIcon className={classes.menuIcon} />Cancelar</>, 
                do: async order => await updateStatus(order, 'canceled') 
            }
        ],
        edit: editOrder,
        delete: deleteOrder
    }

    const statusSteps = [
        { label: 'Recebido', Icon: InputIcon },
        { label: 'Processado', Icon: CheckIcon },
        { label: 'Em trânsito', Icon: LocalShippingIcon },
        { label: 'Entregue', Icon: DoneAllIcon }
    ]

    const columns = [
        { title: 'Ações', render: row => (
            <RowActions target={row} actions={actions} />
        )},
        { title: 'ID', field: '_id', hidden: true },
        { title: 'Cliente', field: 'user.email', searchable: true },
        { title: 'Estado', field: 'status', render: row => {
            if (row.status === "canceled") {
                return (
                    <CancelIcon className={classes.cancelIcon}/>
                );
            }

            let activeStep;
            switch (row.status) {
                case "received": activeStep = 1; break;
                case "processed": activeStep = 2; break;
                case "in transit": activeStep = 3; break;
                case "delivered": activeStep = 4; break;
                default: activeStep = 1;
            }

            return (
                <Stepper alternativeLabel>
                    { statusSteps.map(({ label, Icon }, index) => {
                        return (
                            <Step>
                                <Tooltip title={label}>
                                    <StepLabel StepIconComponent={Icon}>
                                        { activeStep > index && (
                                            <CheckCircleIcon className={classes.checkCircleIcon} />
                                        )}
                                    </StepLabel>
                                </Tooltip>
                            </Step>
                        )    
                    })}
                </Stepper>
            )
        }},
        { title: 'Criado', field: 'createdAt', render: row => 
            moment(new Date(row.createdAt)).format('YYYY-MM-DD HH:mm:ss')
        }
    ]

    useEffect(() => {
        const getOrders = async () => {
            const response = await fetchOrders();
            dispatch({
                type: "SET_ORDERS",
                payload: response.data.content
            }) 
        }
        getOrders();
    }, [dispatch]);

    if (!orders) {
        return (
            <Grid container justify="center" alignItems="center">
                <CircularProgress size={60}/>
            </Grid>
        )
    }

    return (
        <Fade duration={400}>
            <Dialog 
                open={detailsOpen}
                onClose={handleDetailsClose}
                fullScreen
            >
                <OrderDetails order={orderSelected} onClose={handleDetailsClose} />
            </Dialog>
            <ConfirmationModal 
                open={confirmationOpen}
                handleClose={handleConfirmationClose}
                {...confirmationProps}
            />
            <Dialog
                open={formOpen}
                onClose={handleFormClose}
                fullScreen
            >
                <OrderForm values={orderSelected} onClose={handleFormClose} />
            </Dialog>
            <div className={classes.root}>
                <Grid container direction="column" spacing={4}>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleFormOpen}>
                            Adicionar encomenda
                        </Button>
                    </Grid>
                    <Grid item style={{ flex: 1 }}>
                        <Table title="Encomendas" columns={columns} data={orders} />
                    </Grid>
                </Grid>
            </div>
        </Fade>
    );
}

export default compose(
    withContext,
    withStyles(styles)
)(Orders);