const Chartist = require('chartist');

export const chartOptions = {
    lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0
    }),
    low: 0,
    high: 500,
    chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    }
}

export const chartAnimation = {
    draw: function(data) {
        if (data.type === "line" || data.type === "area") {
            data.element.animate({
                d: {
                begin: 600,
                dur: 700,
                from: data.path
                    .clone()
                    .scale(1, 0)
                    .translate(0, data.chartRect.height())
                    .stringify(),
                to: data.path.clone().stringify(),
                easing: Chartist.Svg.Easing.easeOutQuint
                }
            });
        } else if (data.type === "point") {
            data.element.animate({
                opacity: {
                begin: (data.index + 1) * 80,
                dur: 500,
                from: 0,
                to: 1,
                easing: "ease"
                }
            });
        }
    }
}