import React from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import {
    Grid,
    Typography,
    TextField,
    FormHelperText,
    IconButton,
    withStyles,
    Paper,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Fade } from 'react-reveal';

import styles from './styles';
import { withContext } from '../../services/context';
import { updatePage } from '../../services/axios';
import TextEditor from '../TextEditor';
import ImageUpload from '../ImageUpload';
import SubmitButton from '../SubmitButton';

const PageForm = ({
    classes,
    label,
    onClose,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    status,
    setStatus
}) => {
    const handleImageChange = async value => setFieldValue("image", value);

    return (
        <form onSubmit={handleSubmit} className={classes.root} encType="multipart/form-data">
            <Dialog open={!!status.formResponseCode}>
                <DialogContent>
                    <Typography variant="h5">
                        { status.formResponseCode === 201 && "Conteúdo guardado com sucesso" }
                        { status.formResponseCode === 200 && "Conteúdo alterado com sucesso" }
                        { status.formResponseCode > 201 && "Erro no servidor. Tente mais tarde" }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => setStatus({ formResponseCode: null })}>Ok</Button>
                </DialogActions>
            </Dialog>
            <IconButton className={classes.button} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <Paper>
                <Typography variant="h5" className={classes.formTitle}>
                    { label }
                </Typography>
                <Grid container spacing={4} justify="center" direction="column">
                    <Grid item>
                        <Grid container spacing={4}>
                            <Grid item style={{ flex: 1 }}>
                                <TextField 
                                    name="type"
                                    label="Tipo"
                                    multiline
                                    value={values.type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!errors.type && touched.type}
                                    fullWidth
                                />
                                { !!errors.type && touched.type && (
                                    <Fade duration="200">
                                        <FormHelperText error>
                                            { errors.type }
                                        </FormHelperText>
                                    </Fade>
                                )}
                            </Grid>
                            <Grid item style={{ flex: 2 }}>
                                <TextField 
                                    name="title_pt"
                                    label="Título em português"
                                    multiline
                                    value={values.title_pt}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!errors.title_pt && touched.title_pt}
                                    fullWidth
                                />
                                { !!errors.title_pt && touched.title_pt && (
                                    <Fade duration="200">
                                        <FormHelperText error>
                                            { errors.title_pt }
                                        </FormHelperText>
                                    </Fade>
                                )}
                            </Grid>   
                            <Grid item style={{ flex: 2 }}>
                                <TextField 
                                    name="title_en"
                                    label="Título em inglês"
                                    multiline
                                    value={values.title_en}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!errors.title_en && touched.title_en}
                                    fullWidth
                                />
                                { !!errors.title_en && touched.title_en && (
                                    <Fade duration="200">
                                        <FormHelperText error>
                                            { errors.title_en }
                                        </FormHelperText>
                                    </Fade>
                                )}
                            </Grid>          
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextEditor 
                            name="text_pt"
                            label="Texto em português"
                            value={values.text_pt}
                            onChange={setFieldValue}
                            error={!!errors.text_pt && touched.text_pt}
                        />
                        { !!errors.text_pt && touched.text_pt && (
                            <Fade duration="200">
                                <FormHelperText error>
                                    { errors.text_pt }
                                </FormHelperText>
                            </Fade>
                        )}
                    </Grid>
                    <Grid item>
                        <TextEditor 
                            name="text_en"
                            label="Texto em inglês"
                            value={values.text_en}
                            onChange={setFieldValue}
                            error={!!errors.text_en && touched.text_en}
                        />
                        { !!errors.text_en && touched.text_en && (
                            <Fade duration="200">
                                <FormHelperText error>
                                    { errors.text_en }
                                </FormHelperText>
                            </Fade>
                        )}
                    </Grid>
                    <Grid item style={{ flex: 1 }}>
                        <ImageUpload
                            title="Imagem"
                            buttonText="Selecione uma imagem"
                            value={values.image}
                            onChange={handleImageChange}
                        />
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <Grid item>
                                <SubmitButton 
                                    color="primary"
                                    variant="contained"
                                    isSubmitting={isSubmitting}
                                >
                                    Submeter
                                </SubmitButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </form>
    );
}

export default compose(
    withStyles(styles),
    withContext,
    withFormik({
        mapPropsToStatus: () => ({ formResponseCode: null }),
        mapPropsToValues: ({ values }) => {
            if (values) {
                return {
                    _id: values._id,
                    type: values.type,
                    title_pt: values.title_pt,
                    title_en: values.title_en,
                    text_pt: values.text_pt,
                    text_en: values.text_en,
                    image: values.image,
                    published: values.published
                }
            }
            return {
                type: "",
                title_pt: "",
                title_en: "",
                text_pt: "",
                text_en: "",
                image: null,
                published: false
            }
        },
        handleSubmit: async (values, { props: { context: { dispatch } }, setStatus, setSubmitting }) => {
            const response = await updatePage({
                ...values,
                image: values.image._id || values.image
            });
            dispatch({
                type: 'UPDATE_PAGE',
                payload: response.data.content
            });

            setStatus({
                formResponseCode: response.data.status
            });

            setSubmitting(false);
        }
    })
)(PageForm);
