import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import {
    Grid,
    IconButton,
    Typography,
    TextField,
    FormHelperText,
    Paper,
    FormLabel,
    withStyles,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { fetchBlogCategories, fetchProducts, postBlogPost, putBlogPost } from '../../services/axios';
import { Fade } from 'react-reveal';

import { withContext } from '../../services/context';
import styles from './styles';
import Dropzone from '../Dropzone';
import TextEditor from '../TextEditor';
import SelectInput from '../SelectInput';
import SubmitButton from '../SubmitButton';
import ImageUpload from '../ImageUpload';
import { Autocomplete } from '@material-ui/lab';

const BlogPostForm = ({
    label,
    onClose,
    classes,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    context: { categories, products, dispatch },
    isSubmitting,
    status,
    setStatus
}) => {
    console.log(errors);
    useEffect(() => {
        const getCategories = async () => {
            const response = await fetchBlogCategories();
            dispatch({
                type: "SET_CATEGORIES",
                payload: response.data.content
            })
        }
        const getProducts = async () => {
            const response = await fetchProducts();
            dispatch({
                type: "SET_PRODUCTS",
                payload: response.data.content
            })
        }
        getProducts();
        getCategories();
    }, [dispatch])

    const handleThumbnailChange = value => setFieldValue("thumbnail", value);
    
    const handleAddImages = images => {
        setFieldValue("images", images);
    }

    const handleRemoveImage = index => {
        setFieldValue("images", values.images.map((image, i) => i !== index));
    }

    const handleAddProduct = values => {
        setFieldValue("products", values);
    }

    return (
        <form className={classes.root} onSubmit={handleSubmit}>
            <Dialog open={!!status.formResponseCode}>
                <DialogContent>
                    <Typography variant="h5">
                        { status.formResponseCode === 201 && "Publicação guardada com sucesso" }
                        { status.formResponseCode === 200 && "Publicação alterada com sucesso" }
                        { status.formResponseCode > 201 && "Erro no servidor. Tente mais tarde" }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => setStatus({ formResponseCode: null })}>Ok</Button>
                </DialogActions>
            </Dialog>
            <IconButton className={classes.button} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <Paper>
                <Grid container direction="column" justify="center" spacing={4}>
                    <Typography variant="h5">
                        { label }
                    </Typography>
                    <Grid item>
                        <Grid container spacing={4}>
                            <Grid item className={classes.input} justify="space-around">
                                <TextField 
                                    name="title_pt"
                                    value={values.title_pt}
                                    label="Título em português"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.title_pt && touched.title_pt}
                                    fullWidth
                                />
                                { errors.title_pt && touched.title_pt && (
                                    <Fade>
                                        <FormHelperText error>
                                            { errors.title_pt }
                                        </FormHelperText>
                                    </Fade>
                                )}
                            </Grid>
                            <Grid item className={classes.input}>
                                <TextField 
                                    name="title_en"
                                    value={values.title_en}
                                    label="Título em inglês"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.title_en && touched.title_en}
                                    fullWidth
                                />
                                { errors.title_en && touched.title_en && (
                                    <Fade>
                                        <FormHelperText error>
                                            { errors.title_en }
                                        </FormHelperText>
                                    </Fade>
                                )}
                            </Grid>
                            <Grid item className={classes.input}>
                                { categories ? (
                                    <SelectInput
                                        values={values.category}
                                        options={categories}
                                        onChange={category => setFieldValue("category", category)}
                                        labelExtractor="title_pt"
                                        error={!!errors.category && touched.category}
                                    />
                                ) : (
                                    <Grid container justify="center" alignItems="center">
                                        <Grid item>
                                            <CircularProgress size={24} />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        { products ? (
                            <Autocomplete
                                id="products-select"
                                value={values.products}
                                options={products}
                                getOptionLabel={(option) => option.title_pt}
                                onChange={(e, values) => handleAddProduct(values)}
                                style={{ width: "100%" }}
                                multiple
                                renderInput={(params) => <TextField {...params} label="Produtos" variant="outlined" />}
                            />
                        ) : (
                            <Grid container justify="center" alignItems="center">
                                <Grid item>
                                    <CircularProgress size={24} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item>
                        <Grid container justify="space-around" spacing={4}>
                            <Grid item style={{ flex: 1 }}>
                                <Paper className={classes.dropzonePaper}>
                                    <FormLabel>
                                        Imagens do carrossel
                                    </FormLabel>
                                    <Dropzone 
                                        values={values.images}
                                        handleAdd={handleAddImages}
                                        handleRemove={handleRemoveImage}
                                    />
                                    { errors.images && touched.images && (
                                        <FormHelperText error>
                                            { errors.images }
                                        </FormHelperText>
                                    )}
                                </Paper>
                            </Grid>
                            <Grid item style={{ flex: 1 }}>
                                <ImageUpload
                                    buttonText="Selecionar thumbnail"
                                    value={values.thumbnail}
                                    onChange={handleThumbnailChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextEditor 
                            name="smallDescription_pt"
                            label="Descrição em português"
                            value={values.smallDescription_pt}
                            onChange={setFieldValue}
                            error={!!errors.smallDescription_pt && touched.smallDescription_pt}
                        />
                        { !!errors.smallDescription_pt && touched.smallDescription_pt && (
                            <Fade duration="200">
                                <FormHelperText error>
                                    { errors.smallDescription_pt }
                                </FormHelperText>
                            </Fade>
                        )}
                    </Grid>
                    <Grid item>
                        <TextEditor 
                            name="smallDescription_en"
                            label="Descrição em inglês"
                            value={values.smallDescription_en}
                            onChange={setFieldValue}
                            error={!!errors.smallDescription_en && touched.smallDescription_en}
                        />
                        { !!errors.smallDescription_en && touched.smallDescription_en && (
                            <Fade duration="200">
                                <FormHelperText error>
                                    { errors.smallDescription_en }
                                </FormHelperText>
                            </Fade>
                        )}
                    </Grid>
                    <Grid item>
                        <TextEditor 
                            name="text_pt"
                            label="Texto em português"
                            value={values.text_pt}
                            onChange={setFieldValue}
                            error={!!errors.text_pt && touched.text_pt}
                        />
                        { !!errors.text_pt && touched.text_pt && (
                            <Fade duration="200">
                                <FormHelperText error>
                                    { errors.text_pt }
                                </FormHelperText>
                            </Fade>
                        )}
                    </Grid>
                    <Grid item>
                        <TextEditor 
                            name="text_en"
                            label="Texto em inglês"
                            value={values.text_en}
                            onChange={setFieldValue}
                            error={!!errors.text_en && touched.text_en}
                        />
                        { !!errors.text_en && touched.text_en && (
                            <Fade duration="200">
                                <FormHelperText error>
                                    { errors.text_en }
                                </FormHelperText>
                            </Fade>
                        )}
                    </Grid>
                    <Grid item>
                        <Grid container justify="center">
                            <Grid item>
                                <SubmitButton
                                    variant="contained" 
                                    color="primary"
                                    isSubmitting={isSubmitting}
                                >
                                    Submeter
                                </SubmitButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </form>
    )
}

export default compose(
    withContext,
    withStyles(styles),
    withFormik({
        mapPropsToStatus: () => ({ formResponseCode: null }),
        mapPropsToValues: ({ values }) => {
            if (values) {
                return values
            }
            return {
                title_pt: "",
                title_en: "",
                thumbnail: null,
                images: [],
                smallDescription_pt: "",
                smallDescription_en: "",
                text_pt: "",
                text_en: "",
                category: "",
                products: []
            }
        },
        handleSubmit: async (values, { props: { context: { dispatch }}, setSubmitting, setStatus }) => {
            let response;
            if (values._id) {
                response = await putBlogPost({
                    ...values,
                    category: values.category._id,
                    products: values.products.map(product => product._id)
                });
                dispatch({
                    type: "UPDATE_POST",
                    payload: response.data.content
                })
            } else {
                response = await postBlogPost({
                    ...values,
                    category: values.category._id,
                    products: values.products.map(product => product._id)
                });
                dispatch({
                    type: "ADD_POST",
                    payload: response.data.content
                })
            }
            setStatus({ formResponseCode: response.data.status });
            setSubmitting(false);
        },
        validate: values => {
            const errors = {};
            Object.entries(values).forEach(value => {
                if (value[0] ===  "images") {
                    if (value[1].length === 0) {
                        errors[value[0]] = "Por favor adicione imagens"
                    }
                }
                if(value[1] === "" && !value[0].includes('_en')) {
                    errors[value[0]] = "Campo obrigatório"
                }
            })
            return errors;
        }
    })
)(BlogPostForm);