import React, { useState, createContext } from 'react';

const Context = createContext();

const initialState = {
    user: {
        isAuthed: false
    },
    orders: null,
    products: null,
    posts: null,
    categories: null,
    productVariants: null,
    helps: null,
    payments: null,
    siteSettings: null,
    newsletter: null,
    metrics: null,
    footer: null,
    header: null,
    seo: null,
    banners: null,
    contactInfo: null,
    social: null,
    promoCodes: null
}

const reducer = (state, action) => {
    if (!action.payload) {
        return {
            ...state,
            user: {
                isAuthed: false,
                sessionExpired: true
            }
        }
    }
    
    switch (action.type) {
        case "LOGIN_USER":
            return {
                ...state,
                user: {
                    isAuthed: true,
                    ...action.payload
                }
            };
        case "LOGOUT_USER": 
            return {
                ...state,
                user: action.payload
            }
        case "SET_ORDERS": 
            return {
                ...state,
                orders: action.payload
            };
        case "ADD_ORDER":
            return {
                ...state,
                orders: [
                    action.payload,
                    ...state.orders.filter(order => order._id !== action.payload._id),
                ]
            }
        case "UPDATE_ORDER": 
            return {
                ...state,
                orders: [
                    action.payload,
                    ...state.orders.filter(order => order._id !== action.payload._id)
                ]
            }
        case "DELETE_ORDER": 
            return {
                ...state,
                orders: state.orders.filter(order => order._id !== action.payload._id)
            }
        case "SET_PRODUCTS":
            return {
                ...state,
                products: action.payload.results
            };
        case "ADD_PRODUCT": 
            return {
                ...state,
                products: [...state.products, action.payload]
            }
        case "UPDATE_PRODUCT": 
            return {
                ...state,
                products: [
                    action.payload,
                    ...state.products.filter(product => product._id !== action.payload._id),
                ]
            }
        case "DELETE_PRODUCT":
            return {
                ...state,
                products: state.products.filter(product => product._id !== action.payload._id),
            }
        case "SET_PRODUCT_VARIANTS":
            return {
                ...state,
                productVariants: action.payload
            };
        case "UPDATE_VARIANT": 
            return {
                ...state,
                productVariants: [
                    action.payload,
                    ...state.productVariants.filter(v => v._id !== action.payload._id),
                ]
            }
        case "DELETE_VARIANT": 
            return {
                ...state,
                productVariants: [
                    ...state.productVariants.filter((v => v._id !== action.payload._id))
                ]
            }
        case "SET_POSTS":
            return {
                ...state,
                posts: action.payload.results
            }
        case "ADD_POST": 
            return {
                ...state,
                posts: [...state.posts, action.payload]
            }
        case "UPDATE_POST": 
            return {
                ...state,
                posts: [
                    action.payload,
                    ...state.posts.filter(post => post._id !== action.payload._id),
                ]
            }
        case "DELETE_POST": 
            return {
                ...state,
                posts: [
                    ...state.posts.filter((v => v._id !== action.payload._id))
                ]
            }
        case "SET_CATEGORIES":
            return {
                ...state,
                categories: action.payload
            }
        case "UPDATE_CATEGORY": 
            return {
                ...state,
                categories: [
                    action.payload,
                    ...state.categories.filter(v => v._id !== action.payload._id),
                ]
            }
        case "DELETE_CATEGORY":
            return {
                ...state,
                categories: [
                    ...state.categories.filter(v => v._id !== action.payload._id)
                ]
            }
        case "SET_HELPS":
            return {
                ...state,
                helps: action.payload
            };
        case "ADD_HELPS":
            return {
                ...state,
                helps: [
                    action.payload,
                    ...state.helps,
                ]
            }
        case "UPDATE_HELPS":
            return {
                ...state,
                helps: [
                    action.payload,
                    ...state.helps.filter(h => h._id !== action.payload._id),
                ]
            }
        case "DELETE_HELPS": 
            return {
                ...state,
                helps: state.helps.filter(h => h._id !== action.payload._id)
            }
        case "SET_PAYMENTS":
            return {
                ...state,
                payments: action.payload
            }
        case "SET_SITE_SETTINGS":
            return {
                ...state,
                siteSettings: action.payload
            }
        case "SET_NEWSLETTER":
            return {
                ...state,
                newsletter: action.payload
            }
        case "SET_METRICS":
            return {
                ...state,
                metrics: action.payload
            }
        case "SET_HEADER":
            return {
                ...state,
                header: action.payload
            };
        case "SET_FOOTER":
            return {
                ...state,
                footer: action.payload
            };
        case "SET_PAGES":
            return {
                ...state,
                pages: action.payload
            };
        case "UPDATE_PAGE":
            return {
                ...state,
                pages: [
                    action.payload,
                    ...state.pages.filter(p => p._id !== action.payload._id),
                ]
            };
        case "DELETE_PAGE":
            return {
                ...state,
                pages: state.pages.filter(p => p._id !== action.payload._id)
            };
        case 'SET_SEO': 
            return {
                ...state,
                seo: action.payload
            };
        case "SET_BANNERS":
            return {
                ...state,
                banners: action.payload
            }
        case "ADD_BANNER": 
            return {
                ...state,
                banners: [
                    action.payload,
                    ...state.banners
                ]
            }
        case "UPDATE_BANNER":
            return {
                ...state,
                banners: [
                    action.payload,
                    ...state.banners.filter(b => b._id !== action.payload._id)
                ]
            }
        case "DELETE_BANNER":
            return {
                ...state,
                banners: state.banners.filter(b => b._id !== action.payload._id)
            }
        case "SET_CONTACT_INFO":
            return {
                ...state,
                contactInfo: action.payload
            }
        case "SET_SOCIAL":
            return {
                ...state,
                social: action.payload
            }
        case "ADD_SOCIAL":
            return {
                ...state,
                social: [
                    ...state.social,
                    action.payload
                ]
            }
        case "UPDATE_SOCIAL": 
            return {
                ...state,
                social: [
                    ...state.social.filter(b => b._id !== action.payload._id),
                    action.payload
                ]
            }
        case "REMOVE_SOCIAL":
            return {
                ...state,
                social: state.social.filter(b => b._id !== action.payload)
            }
        case "SET_PROMO_CODES":
            return {
                ...state,
                promoCodes: action.payload
            }
        case "ADD_PROMO_CODE":
            return {
                ...state,
                promoCodes: [
                    ...state.promoCodes,
                    action.payload
                ]
            }
        case "UPDATE_PROMO_CODE": 
            return {
                ...state,
                promoCodes: [
                    ...state.promoCodes.filter(pc => pc._id !== action.payload._id),
                    action.payload
                ]
            }
        case "REMOVE_PROMO_CODE":
            return {
                ...state,
                promoCodes: state.promoCodes.filter(pc => pc._id !== action.payload._id)
            }
        default:
            return state;
    }
}

export const Provider = ({ children }) => {
    const [state, setState] = useState({
        ...initialState,
        dispatch: action => setState(state => reducer(state, action))
    });

    return (
        <Context.Provider value={state}>
            { children }
        </Context.Provider>
    )
}

export const withContext = WrappedComponent => props => (
        <Context.Consumer>
            { value => <WrappedComponent {...props} context={value} /> }
        </Context.Consumer>
    ); 