export default () => ({
    root: {
        padding: 20
    },
    dropzone: {
        border: "2px solid black",
        borderRadius: 10,
        height: 50,
        padding: 20
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16,
        zIndex: 10
    },  
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 50,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box',
        justifyContent: 'center'
    },
    deleteButton: {
        display: "block",
        position: "absolute",
        margin: "auto",
        padding: "auto"
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    },
    img: {
        display: 'block',
        width: 'auto',
        margin: 'auto',
        height: '100%'
    }  
})