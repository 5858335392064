import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { Grid, Button, withStyles, CircularProgress, Dialog } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { withContext } from '../../services/context';
import styles from './styles';
import Table from '../../components/Table';
import RowActions from '../../components/Table/RowActions';
import { fetchPromoCodes, removePromoCodes, updatePublishPromoCode } from '../../services/axios';
import { Fade } from 'react-reveal';
import PromoCodeForm from '../../components/PromoCodeForm';
import ConfirmationModal from '../../components/ConfirmationModal';
import moment from 'moment';

const PromoCodes = ({ classes, context: { promoCodes, dispatch } }) => {
    const [formOpen, setFormOpen] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationProps, setConfirmationProps] = useState(null);

    const handleConfirmationOpen = () => setConfirmationOpen(true);

    const handleConfirmationClose = () => {
        setConfirmationOpen(false)
        setConfirmationProps(null);
    };

    const handleFormOpen = () => setFormOpen(true)

    const handleFormClose = () => setFormOpen(false);

    useEffect(() => {
        const getPromoCodes = async () => {
            const response = await fetchPromoCodes();
            dispatch({
                type: "SET_PROMO_CODES",
                payload: response.data.content
            })
        }
        getPromoCodes();
    }, [dispatch])

    const publishPromoCode = promoCode => {
        setConfirmationProps({
            title: "Publicar produto",
            text: `Deseja publicar o produto ${promoCode.code}?`,
            action: async () => {
                const response = await updatePublishPromoCode(promoCode._id, !promoCode.published);
                dispatch({
                    type: "UPDATE_PROMO_CODE",
                    payload: response.data.content
                })
            }
        })
        handleConfirmationOpen();
    }

    const deletePromoCode = promoCode => {
        setConfirmationProps({
            title: "Apagar código promocional",
            text: `Deseja apagar o código promocional ${promoCode.code}?`,
            action: async () => {
                const response = await removePromoCodes(promoCode._id);
                dispatch({
                    type: "REMOVE_PROMO_CODE",
                    payload: response.data.content
                })
            }
        });
        handleConfirmationOpen();
    }

    const actions = {
        publish: publishPromoCode,
        delete: deletePromoCode,
    }

    const columns = [
        { title: 'Ações', render: row => (
            <RowActions target={row} actions={actions} />
        )},
        { title: 'Código', field: 'code', searchable: true },
        { title: 'Valor (%)', field: 'value' },
        { title: 'Início', field: 'startAt', render: row =>
            moment(row.startAt).format('YYYY-MM-DD')
        },
        { title: 'Fim', field: 'endAt', render: row =>
            moment(row.endAt).format('YYYY-MM-DD')
        },
        { title: 'Publicado', field: 'published', render: row =>
            row.published ? <CheckIcon /> : <CloseIcon />
        }
    ]

    if (!promoCodes) {
        return (
            <Grid container justify="center" alignItems="center">
                <CircularProgress size={60}/>
            </Grid>
        )
    }

    console.log(promoCodes);

    return (
        <Fade>
            <ConfirmationModal
                open={confirmationOpen}
                handleClose={handleConfirmationClose}
                {...confirmationProps}
            />
            <Dialog
                open={formOpen} 
                onClose={handleFormClose}
                maxWidth="lg"
            >
                <PromoCodeForm
                    label="Adicionar código promocional"
                    onClose={handleFormClose} 
                />
            </Dialog>
            <div className={classes.root}>
                <Grid container direction="column" spacing={4}>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleFormOpen}>
                            Adicionar código promocional
                        </Button>
                    </Grid>
                    <Grid item style={{ flex: 1 }}>
                        <Table title="Códigos promocionais" columns={columns} data={promoCodes} />
                    </Grid>
                </Grid>
            </div>
        </Fade>
    )
};

export default compose(
    withContext,
    withStyles(styles)
)(PromoCodes);