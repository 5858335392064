import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import {
    Grid,
    TextField,
    Paper,
    FormHelperText,
    withStyles
} from '@material-ui/core';
import { withFormik } from 'formik';

import background from '../../assets/background.jpg'
import styles from './styles';
import { withContext } from '../../services/context';
import SubmitButton from '../../components/SubmitButton';
import SessionExpired from '../../components/SessionExpired';
import { loginUser } from '../../services/axios';
import { decodeToken, setToken } from '../../services/storage';

const Login = ({
    context: { user },
    classes, 
    values, 
    handleChange, 
    handleBlur, 
    handleSubmit,
    isSubmitting,
    errors,
    touched
}) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (user.sessionExpired) handleOpen();
    }, [user.sessionExpired])

    return (
        <div 
            className={classes.root} 
            style={{ backgroundImage: `url(${background})`}}
        >
            <SessionExpired open={open} onClose={handleClose}/>
            <Grid 
                container
                className={classes.container}
                justify="center"
                align="center"
            >
                <Grid item>
                    <Paper className={classes.paper}>
                        <form onSubmit={handleSubmit}>
                            <Grid 
                                container 
                                direction="column" 
                                spacing={4}
                                justify="space-around"
                            >
                                <Grid item>
                                    <TextField 
                                        name="email"
                                        label="Login"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.email && touched.email}
                                    />
                                    { !!errors.email && touched.email && (
                                        <FormHelperText error>
                                            { errors.email }
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item>
                                    <TextField 
                                        name="password"
                                        type="password"
                                        label="Password"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.password && touched.password}
                                    />
                                    { !!errors.password && touched.password && (
                                        <FormHelperText error>
                                            { errors.password }
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <SubmitButton 
                                    type="submit"
                                    variant="contained" 
                                    color="primary"
                                    isSubmitting={isSubmitting}
                                >
                                    Entrar
                                </SubmitButton>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default compose(
    withContext,
    withStyles(styles),
    withFormik({
        mapPropsToValues: () => ({
            email: "",
            password: ""
        }),
        handleSubmit: async (values, { props: { context }, setErrors }) => {
            try {
                const response = await loginUser(values.email, values.password);
                await setToken(response.data.content.access_token);
                const userData = await decodeToken(response.data.content.access_token);
                context.dispatch({
                    type: "LOGIN_USER",
                    payload: userData
                });
            } catch (err) {
                switch (err.response.status) {
                    case 404: 
                        setErrors({ email: "Este utilizador não existe" });
                        break;
                    case 401:
                        setErrors({ password: "Password incorrecta" });
                        break;
                    default:
                        setErrors({ form: "Houve um problema com a submissão. Por favor tente mais tarde" })
                }
            }
        }
    })
)(Login);