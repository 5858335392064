import React from 'react';
import { compose } from 'recompose';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    withStyles
} from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import history from '../../services/history';
import styles from './styles';
import { withContext } from '../../services/context';
import { routes } from '../../services/router';
import { Fade } from 'react-reveal';
import { deleteToken } from '../../services/storage';

const Sidebar = ({ classes, context: { user, dispatch } }) => {
    if (!user.isAuthed) {
        return null;
    }

    const logout = () => {
        deleteToken();
        dispatch({
            type: "LOGOUT_USER",
            payload: {
                isAuthed: false
            }
        });
    }

    return (
        <Drawer classes={{ paper: classes.paper }} variant="permanent">
            <div className={classes.backgroundImage}></div>
            <List className={classes.list}>
                {
                    routes.map((route, index) => (
                        <Fade key={index} left duration={100} delay={100*index}>
                            <ListItem 
                                className={ history.location.pathname === route.path ? classes.listItemActive : classes.listItem  }
                                button 
                                onClick={() => history.push(route.path)}
                            >
                                <ListItemIcon><route.icon color="secondary" /></ListItemIcon>
                                <ListItemText>{ route.label }</ListItemText>
                            </ListItem>
                        </Fade>
                    ))
                }
                <ListItem className={classes.listItem} button onClick={logout}>
                    <ListItemIcon><ExitToAppIcon color="secondary" /></ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </ListItem>
            </List>
        </Drawer>
    )
}

export default compose(
    withContext,
    withStyles(styles)
)(Sidebar);