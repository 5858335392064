export default () => ({
    root: {
        padding: 20
    },
    paperTabs: {
        margin: 30
    },
    formContainer: {
        padding: 20,
        margin: "auto"
    },
    form: {
        padding: 30,
        width: "70%",
        margin: "auto"
    },
    thumbnail: {
        height: 200,
        width: 200,
        marginBottom: 20
    },
    button: {
        position: "fixed",
        right: 0,
        top: 0
    },
    title: {
        marginBottom: 20
    },
    input: {
        flex: 1
    },
    selectInput: {
        width: "100%"
    },
    thumbnailButton: {
        marginTop: 20,
        marginBottom: 20
    },
})