import { COLOR_TERTIARY, COLOR_QUATERNARY } from '../../theme/colors';

export default theme => ({
    root: {
        paddingLeft: 250
    },
    cardContainer: {
        flex: 1
    },
    ctChart: {
        '& .ct-label': { fill: 'white' },
    },
    successText: {
        color: COLOR_TERTIARY
    },
    upArrowCardCategory: {
        width: "16px",
        height: "16px"
    },
    productThumbnail: {
        width: "80px",
        height: "auto"
    },
    stats: {
        color: COLOR_QUATERNARY,
        display: "inline-flex",
        fontSize: "12px",
        lineHeight: "22px",
        "& svg": {
        top: "4px",
        width: "16px",
        height: "16px",
        position: "relative",
        marginRight: "3px",
        marginLeft: "3px"
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        top: "4px",
        fontSize: "16px",
        position: "relative",
        marginRight: "3px",
        marginLeft: "3px"
        }
    },
    paper: {
        margin: "16px",
        padding: "10px",
        width: "100%"
    },
    card: {
        paddingBottom: "20px"
    },
    cardBody: {
        padding: "20px"
    },
    cardCategory: {
        color: COLOR_TERTIARY,
        margin: "0",
        fontSize: "20px",
        marginTop: "0",
        paddingTop: "10px",
        marginBottom: "0"
    },
    cardCategoryWhite: {
        color: "white",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: COLOR_TERTIARY,
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: COLOR_TERTIARY,
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    cardTitleWhite: {
        color: "white",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: COLOR_TERTIARY,
        fontWeight: "400",
        lineHeight: "1"
        }
    }
})