import jwt_decode from 'jwt-decode';
const { getItem, setItem, removeItem } = require('localforage');

export const decodeToken = token => jwt_decode(token);

export const setToken = async token => await setItem('all4health-token', token);

export const getToken = async () => await getItem('all4health-token');

export const deleteToken = async () => await removeItem('all4health-token');

export const sessionIsExpired = async () => {
    const access_token = await getToken();
    if (access_token) {
        const tokenData = await decodeToken(access_token);
        const { exp } = tokenData;
        return (exp*1000) < Date.now() ? null : tokenData;
    }
    return null;
}