export default () => ({
    root: {
        padding: 20
    },
    form: {
        padding: 30
    },
    button: {
        position: "absolute",
        right: 0,
        top: 0
    }
})