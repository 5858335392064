export default () => ({
    root: {

    },
    closeButton: {
        zIndex: 100,
        position: "fixed",
        top: 0,
        right: 0
    },
    mainTitle: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    paper: {
        padding: "50px"
    },
    section: {
        flex: 1
    },
    userSection: {
        flex: 1
    },
    itemSection: {
        flex: 2
    },
    input: {
        color: "black"
    },
    thumbnail: {
        height: "300px"
    }
})