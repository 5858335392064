import React from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import {
    Grid,
    Paper,
    TextField,
    withStyles,
    Typography,
    FormHelperText,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';

import styles from './styles';
import SubmitButton from '../SubmitButton';
import { postSEO } from '../../services/axios';
import { withContext } from '../../services/context';

const SEOForm = ({
    classes,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    status,
    setStatus
}) => {
    return (
        <form className={classes.root} onSubmit={handleSubmit}>
            <Dialog open={!!status.formResponseCode}>
                <DialogContent>
                    <Typography variant="h5">
                        { status.formResponseCode === 201 && "SEO guardado com sucesso" }
                        { status.formResponseCode === 200 && "SEO alterado com sucesso" }
                        { status.formResponseCode > 201 && "Erro no servidor. Tente mais tarde" }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => setStatus({ formResponseCode: null })}>Ok</Button>
                </DialogActions>
            </Dialog>
            <Paper>
                <Typography variant="h5">SEO</Typography>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <TextField 
                            name="title"
                            label="Website title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.title && !!touched.title}
                            fullWidth
                        />
                        {!!errors.title && !!touched.title && (
                            <FormHelperText error>{ errors.title }</FormHelperText>
                        )}
                    </Grid>
                    <Grid item>
                        <TextField 
                            name="description"
                            label="Meta description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.description && !!touched.description}
                            rows={5}
                            multiline
                            fullWidth
                        />
                        {!!errors.description && !!touched.description && (
                            <FormHelperText error>{ errors.description }</FormHelperText>
                        )}
                    </Grid>
                    <Grid item>
                        <TextField 
                            name="keywords"
                            label="Meta keywords"
                            value={values.keywords}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.keywords && !!touched.keywords}
                            rows={5}
                            multiline
                            fullWidth
                        />
                        {!!errors.keywords && !!touched.keywords && (
                            <FormHelperText error>{ errors.keywords }</FormHelperText>
                        )}
                    </Grid>
                    <Grid item>
                        <TextField 
                            name="urlContent"
                            label="URL Content"
                            value={values.urlContent}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.urlContent && !!touched.urlContent}
                            fullWidth
                        />
                        {!!errors.urlContent && !!touched.urlContent && (
                            <FormHelperText error>{ errors.urlContent }</FormHelperText>
                        )}
                    </Grid>
                    <Grid item>
                        <TextField 
                            name="author"
                            label="Author"
                            value={values.author}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.author && !!touched.author}
                            fullWidth
                        />
                        {!!errors.author && !!touched.author && (
                            <FormHelperText error>{ errors.author }</FormHelperText>
                        )}
                    </Grid>
                    <Grid item>
                        <TextField 
                            name="company"
                            label="Company"
                            value={values.company}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.company && !!touched.company}
                            fullWidth
                        />
                        {!!errors.company && !!touched.company && (
                            <FormHelperText error>{ errors.company }</FormHelperText>
                        )}
                    </Grid>
                    <Grid item>
                        <TextField 
                            name="replyToContent"
                            label="Reply-To Content"
                            value={values.replyToContent}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.replyToContent && !!touched.replyToContent}
                            fullWidth
                        />
                        {!!errors.replyToContent && !!touched.replyToContent && (
                            <FormHelperText error>{ errors.replyToContent }</FormHelperText>
                        )}
                    </Grid>
                    <Grid item>
                        <TextField 
                            name="copyrightContent"
                            label="Copyright Content"
                            value={values.copyrightContent}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.copyrightContent && !!touched.copyrightContent}
                            fullWidth
                        />
                        {!!errors.copyrightContent && !!touched.copyrightContent && (
                            <FormHelperText error>{ errors.copyrightContent }</FormHelperText>
                        )}
                    </Grid>
                    <Grid item>
                        <TextField 
                            name="madeContent"
                            label="Made Content"
                            value={values.madeContent}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.madeContent && !!touched.madeContent}
                            fullWidth
                        />
                        {!!errors.madeContent && !!touched.madeContent && (
                            <FormHelperText error>{ errors.madeContent }</FormHelperText>
                        )}
                    </Grid>
                    <Grid item>
                        <Grid container justify="center" alignItems="center">
                            <Grid item>
                                <SubmitButton 
                                    variant="contained"
                                    color="primary"
                                    isSubmitting={isSubmitting}
                                >
                                    Submeter
                                </SubmitButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </form>
    )
}

export default compose(
    withContext,
    withStyles(styles),
    withFormik({
        mapPropsToStatus: () => ({ formResponseCode: null }),
        mapPropsToValues: ({ values }) => {
            return {
                _id: values._id || "",
                title: values.title || "",
                description: values.description || "",
                keywords: values.keywords || "",
                urlContent: values.urlContent || "", 
                author: values.author || "",
                company: values.company || "",
                replyToContent: values.replyToContent || "",
                copyrightContent: values.copyrightContent || "",
                madeContent: values.madeContent || ""
            }
        },
        handleSubmit: async (values, { props: { context: { dispatch } }, setSubmitting, setStatus }) => {
            const response = await postSEO(values);
            dispatch({
                type: 'SET_SEO',
                payload: response.data.content
            });
            setSubmitting(false);
            setStatus({ formResponseCode: response.data.status });
        }
    })
)(SEOForm)