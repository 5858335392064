import React from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import { 
    Grid,
    TextField,
    Typography,
    withStyles
} from '@material-ui/core';
import NumberFormat from 'react-number-format';

import { withContext } from '../../services/context';
import styles from './styles';
import SubmitButton from '../SubmitButton';
import { putProduct } from '../../services/axios';

const EditDiscountModal = ({ 
    classes,
    product,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting
}) => {
    return (
        <form onSubmit={handleSubmit} class={classes.formContainer}>
            <Grid container spacing={4} justify="center" alignItems="center">
                <Typography variant="h5">
                    Editar desconto de { product?.title_pt }
                </Typography>
                <Grid item>
                    <NumberFormat
                        name="discount"
                        label="Desconto (%)"
                        value={values.discount}
                        decimalSeparator="."
                        thousandSeparator=""
                        decimalScale={0}
                        max={100}
                        customInput={TextField}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <SubmitButton variant="contained" color="primary" isSubmitting={isSubmitting}>
                        Submeter
                    </SubmitButton>
                </Grid>
            </Grid>
        </form>
    );
}

export default compose(
    withContext,
    withStyles(styles),
    withFormik({
        mapPropsToValues: ({ product }) => ({
            ...product,
            discount: product?.discount
        }),
        handleSubmit: async (values, { props: { context: { dispatch } }}) => {
            const response = await putProduct(values);

            dispatch({
                type: "UPDATE_PRODUCT",
                payload: response.data.content
            })
        }
    }),
)(EditDiscountModal);