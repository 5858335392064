import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Tooltip,
    Menu,
    MenuItem,
    IconButton,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import InfoIcon from '@material-ui/icons/Info';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';

const RowActions = ({ target, actions }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const ActionButtons = {
        edit: {
            tooltip: "Editar",
            icon: EditIcon
        },
        delete: {
            tooltip: "Apagar",
            icon: DeleteIcon
        },
        publish: {
            tooltip: "Publicar",
            icon: PublishIcon
        },
        changeStatus: {
            tooltip: "Mudar estado",
            icon: LabelImportantIcon
        },
        view: {
            tooltip: "Detalhes",
            icon: InfoIcon
        },
        editDiscount: {
            tooltip: "Editar desconto",
            icon: MoneyOffIcon
        },
        editStatuses: {
            tooltip: "Editar estados",
            icon: ControlPointDuplicateIcon
        }
    }

    const handleMenuOpen = e => setAnchorEl(e.currentTarget);

    const handleMenuClose = () => setAnchorEl(null);

    return (
        <Grid container justify="center">
            { Object.entries(actions).map((action, index) => {
                if (ActionButtons[action[0]]) {
                    const Icon = ActionButtons[action[0]].icon;
                    return (
                        <Grid item key={index}>
                            <Tooltip title={ActionButtons[action[0]].tooltip}>
                                <IconButton
                                    variant="contained"
                                    onClick={
                                        !Array.isArray(action[1]) ? () => action[1](target) : handleMenuOpen
                                    }
                                >
                                    <Icon />
                                </IconButton>
                            </Tooltip>
                            { Array.isArray(action[1]) && (
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    { action[1].map(action => (
                                        <MenuItem onClick={() => {
                                            action.do(target)
                                            setAnchorEl(null);
                                        }}>
                                            { action.label }
                                        </MenuItem>
                                    ))}
                                </Menu>
                            )}
                        </Grid>
                    )
                }
                return null
            })}
        </Grid>
    )
}

RowActions.propTypes = {
    target: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
}

export default RowActions