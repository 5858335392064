import backgroundImage from '../../theme/img/sidebar-bg.jpeg';

export default theme => ({
    paper: {
        padding: 20,
        width: 200,
        boxShadow: "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);",
        backgroundColor: '#000'
    },
    backgroundImage: {
        position: "absolute",
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.4
    },
    list: {
        color: theme.palette.secondary.main
    },
    listItem: {
        borderRadius: '6px',
        '&:hover': {
            backgroundColor: 'rgba(200, 200, 200, 0.2)'
        }
    },
    listItemActive: {
        borderRadius: '6px',
        backgroundColor: '#00acc1',
        boxShadow: '0 12px 20px -10px rgba(0, 172, 193,.28), 0 4px 20px 0 rgba(0, 0, 0,.12), 0 7px 8px -5px rgba(0, 172, 193,.2)',
        '&:hover': {
            backgroundColor: '#00acc1'
        },
        transition: '0.5s'
    }
})