import React from 'react';
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button
} from '@material-ui/core';

import SubmitButton from '../../SubmitButton';

const ConfirmationModalContent = ({ 
    handleClose, 
    confirmed, 
    error, 
    title, 
    text, 
    handleAction,
    isSubmitting
}) => {
    if (confirmed && !error) {
        return (
            <>
                <DialogTitle>
                    Sucesso
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Operação realizada com sucesso
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleClose}>
                        Ok
                    </Button>
                </DialogActions>
            </>       
        )
    }

    if (confirmed && error) {
        return (
            <>
                <DialogTitle>
                    Erro
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Houve um erro inesperado no servidor
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleClose}>
                        Ok
                    </Button>
                </DialogActions>
            </>
        )  
    }

    return (
        <>
            <DialogTitle>
                { title }
            </DialogTitle>
            <DialogContent>
                <Typography>
                    { text }
                </Typography>
            </DialogContent>
            <DialogActions>
                <SubmitButton 
                    variant="contained"
                    color="primary" 
                    onClick={handleAction}
                    isSubmitting={isSubmitting}
                >
                    Sim
                </SubmitButton>
                <Button variant="contained" color="primary" onClick={handleClose}>
                    Não
                </Button>
            </DialogActions>
        </>  
    )
}

export default ConfirmationModalContent;