import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import {
    Typography,
    Grid,
    withStyles,
    TextField,
    IconButton,
    CircularProgress,
    Fade,
    FormHelperText,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { withContext } from '../../services/context';
import styles from './styles';
import SubmitButton from '../SubmitButton';
import SelectInput from '../SelectInput';
import { 
    fetchProductVariants, 
    postProductVariant, 
    putProductVariant 
} from '../../services/axios';

const CategoryForm = ({
    context: { productVariants, dispatch },
    classes,
    label,
    onClose,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    status,
    setStatus
}) => {
    useEffect(() => {
        const getVariantOptions = async () => {
            const response = await fetchProductVariants();
            dispatch({
                type: "SET_PRODUCT_VARIANTS",
                payload: response.data.content
            });
        }
        getVariantOptions();
    }, [dispatch]);

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Dialog open={!!status.formResponseCode}>
                <DialogContent>
                    <Typography variant="h5">
                        { status.formResponseCode === 201 && "Categoria guardada com sucesso" }
                        { status.formResponseCode === 200 && "Categoria alterada com sucesso" }
                        { status.formResponseCode > 201 && "Erro no servidor. Tente mais tarde" }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => setStatus({ formResponseCode: null })}>Ok</Button>
                </DialogActions>
            </Dialog>
            <IconButton className={classes.button} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <Grid container direction="column" spacing={4} justify="center">
                <Typography variant="h5" color="primary">
                    { label }
                </Typography>
                <Grid item>
                    <TextField 
                        name="title_pt"
                        label="Nome em português"
                        value={values.title_pt}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.title_pt && touched.title_pt}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField 
                        name="title_en"
                        label="Nome em inglês"
                        value={values.title_en}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.title_en && touched.title_en}
                        fullWidth
                    />
                </Grid>
                <Grid item className={classes.input}>
                    {productVariants ? (
                        <SelectInput
                            values={values.parent}
                            label="Categoria (Pai)"
                            options={[
                                { _id: ' ', title_pt: "Sem pai", category: "/" },
                                ...productVariants
                            ]}
                            onChange={variants => setFieldValue("parent", variants)}
                            labelExtractor="title_pt"
                            error={!!errors.variants && touched.variants}
                        />
                    ) : (
                        <CircularProgress size={24} />
                    )}
                    { !!errors.variants && touched.variants && (
                        <Fade duration="200">
                            <FormHelperText error>
                                { errors.variants }
                            </FormHelperText>
                        </Fade>
                    )}
                </Grid>
                <Grid item>
                    <Grid container justify="center">
                        <SubmitButton 
                            variant="contained"
                            color="primary"
                            isSubmitting={isSubmitting}
                        >
                            Submeter
                        </SubmitButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default compose(
    withContext,
    withStyles(styles),
    withFormik({
        mapPropsToStatus: () => ({ formResponseCode: null }),
        mapPropsToValues: ({ values, context: { productVariants } }) => {
            if (values) {
                return {
                    ...values,
                    parent: productVariants.find(pv => pv.category === values.parent)
                };
            }
            return {
                title_pt: "",
                title_en: "",
                parent: { title_pt: "Sem pai", category: "/" },
            }
        },
        handleSubmit: async (values, { props: { context: { dispatch } }, setSubmitting, setStatus }) => {
            let response;

            if (values._id) {
                response = await putProductVariant({
                    ...values,
                    parent: `${values.parent.category}`
                });
            } else {
                response = await postProductVariant({
                    ...values,
                    parent: `${values.parent.category}`
                });
            }
            dispatch({
                type: "UPDATE_VARIANT",
                payload: response.data.content
            })
            setStatus({ formResponseCode: response.data.status });
            setSubmitting(false);
        } 
    })
)(CategoryForm);