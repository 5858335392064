import React from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import {
    Grid,
    TextField,
    Paper,
    Typography,
    withStyles
} from '@material-ui/core';

import { withContext } from '../../services/context';
import { updateSettings } from '../../services/axios';
import styles from './styles';
import SubmitButton from '../../components/SubmitButton';

const SiteSettingsForm = ({
    classes,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting
}) => {    
    return (
        <form className={classes.root} onSubmit={handleSubmit}>
            <Paper className={classes.paper}>
                <Typography variant="h5" className={classes.formTitle}>
                    Configurações do site
                </Typography>
                <Grid container spacing={4} justify="center">
                    <Grid item className={classes.inputContainer}>
                        <TextField
                            name="shippingCost"
                            label="Valor de portes"
                            value={values.shippingCost}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.shippingCost && touched.shippingCost}
                            fullWidth
                        />
                    </Grid>
                    <Grid item className={classes.inputContainer}>
                        <TextField 
                            name="freeShippingCostMinimum"
                            label="Portes grátis a partir de"
                            value={values.freeShippingCostMinimum}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.freeShippingCostMinimum && touched.freeShippingCostMinimum}
                            fullWidth
                        />
                    </Grid>
                    <Grid item className={classes.inputContainer}>
                        <TextField 
                            name="IVA"
                            label="IVA"
                            value={values.IVA}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.IVA && touched.IVA}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <Grid container justify="center">
                            <Grid item>
                                <SubmitButton 
                                    variant="contained"
                                    color="primary"
                                    isSubmitting={isSubmitting}
                                >
                                    Guardar
                                </SubmitButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </form>
    );
}

export default compose(
    withContext,
    withStyles(styles),
    withFormik({
        mapPropsToValues: ({ values }) => {
            if (values) {
                return {
                    id: values._id,
                    shippingCost: values.shippingCost,
                    freeShippingCostMinimum: values.freeShippingCostMinimum,
                    IVA: values.IVA
                }
            }
            return {
                shippingCost: "",
                freeShippingCostMinimum: "",
                IVA: ""
            }
        },
        handleSubmit: async (values, { props: { context: { dispatch } } }) => {
            const response = await updateSettings(values.id, values);
            dispatch({
                type: 'SET_SITE_SETTINGS',
                payload: response.data.content
            })
        }
    })
)(SiteSettingsForm);